import React from 'react'
import ch from './chart.module.scss';
import {useNavigate} from 'react-router-dom';
import {Button} from 'antd';
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {UIHelpers} from '@vas-common/ui-kit';


const colors= {withEsim: '#00a400', total: '#5d9cec'}

const RegistrationsChart = (props: {
  data: any,
  height?: number,
}): JSX.Element => {
  const { data, height = 150} = props;
  const navigator = useNavigate()

  return <div className={ch.chart}>
    <div className={ch.head}>
      <h3 className={ch.title}>User registrations</h3>
      <div className={ch.controls}>

        <Button type="primary" onClick={() => navigator( '/users')}>
          Show all
        </Button>
      </div>

    </div>
    <ResponsiveContainer width="100%" height={height}>
      <BarChart
        data={data}
        maxBarSize={10}>
        <CartesianGrid stroke="#dedede" strokeDasharray="1 1"/>
        <YAxis tickFormatter={v => {
          return `${UIHelpers.formatNumber(v).toLocaleString()}`;
        }}/>
        <XAxis dataKey={'date'} tick={{fontSize: 11}}/>
        <Tooltip/>
        {<>
          <Bar dataKey={'Total'} stackId="a" fill={colors.total}/>
          <Bar dataKey={'With sim'} stackId="b" fill={colors.withEsim}/>
        </>}

      </BarChart>
    </ResponsiveContainer>

  </div>
}


export default RegistrationsChart;
