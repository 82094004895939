import {AdminTable, Container} from '@vas-common/ui-kit';
import React, { useEffect, useState} from 'react';
import { fetchEsims} from '../../../api/other';
import {Pagination, PaginationProps} from 'antd';
import up from '../../UsersPage/users.module.scss';
import qs from 'qs';
import EsimsTable from './EsimsTable';

const ESimsBlock = ({userId}: { userId: any }) => {
  const [filters, setFilters] = useState<any>({
    sort: {
      created_at: 'desc'
    },
    "filter[customer_id]": userId,
    limit: 10,
    page: 1,
  });

  const [esims, setEsims] = useState<any>({
    data: {},
    meta: {
      total: 0
    }
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
      setLoading(true)
      fetchEsims(`?${qs.stringify(filters)}`)
      .then(data => {
        setEsims(data);
      }).finally(() => setLoading(false));
    },
    [filters]
  )

  const toggleSort = (selectedColumn: string) => {
    const currentColumn = filters.sort ? Object.keys(filters.sort)[0] : undefined;
    const isCurrentColumn = selectedColumn === currentColumn
    const sort = {
      [selectedColumn]: isCurrentColumn
        ? filters.sort[selectedColumn] === 'asc' ? 'desc' : 'asc'
        : 'desc'
    }
    setFilters({
      ...filters, sort, page: 1
    })
  }

  const onChangePage: PaginationProps['onChange'] = (page, sizePage) => {
    setFilters({...filters, page: page, limit: sizePage})
  };
  return esims?.meta?.total > 0 ? <Container>
      <AdminTable
        isPage={false}
        title={'eSIMs'} caption={loading ||
        esims.meta.total && `${esims.meta.total} found`}>
        <EsimsTable
          data={esims.data}
          orderColumn={Object.keys(filters.sort)[0]}
          isOrderColumn={(filters.sort[Object.keys(filters.sort)[0]] === 'desc')}
          onClick={toggleSort}
        />
        {esims?.data?.length != esims?.meta?.total ? <Pagination
            className={up.pagination}
            current={filters.page}
            onChange={onChangePage}
            showSizeChanger
            pageSize={filters.limit} total={esims?.meta?.total}/>
          : ''}
      </AdminTable>
    </Container>
    : <></>
}
export default ESimsBlock;