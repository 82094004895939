import React, {FC} from "react";
import moment from "moment";
import {Link} from "react-router-dom";
import {CheckIcon, getSignIcon} from "../../../../components/Icons";
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import {getFullName} from "../../../../api/helper";
import s from './row.module.scss'
import {TCustomer} from '../../../../api/Types/customers';

type Prop = {
  item: TCustomer,
  onClick?: () => void
}

const Row: FC<Prop> = ({item}) => {
  return (
    <tr>
      <td className={'disabled'}>{item.id}</td>
      <td><Link className={'link'} to={`/users/${item.id}`}>
        <span className={`material-icons-outlined ${s.icon}`}>person_outline</span>
        <strong>{getFullName(item.last_name, item.first_name)}</strong>
      </Link></td>
      <td>{item.email ? item.email : <span className="disabled">none</span>}</td>
      <td className={s.signWithIcon}>{getSignIcon(item?.auth_with)}</td>
      <td>{item.country ? getUnicodeFlagIcon(item.country) : <span className="disabled">none</span>} {item.country}</td>
      <td>
        <CheckIcon enable={Boolean(item?.has_active_esim)}/>
      </td>
      <td>
        <CheckIcon enable={Boolean(item?.has_active_package)}/>
      </td>
      <td>
        {item.last_active_at ? moment(item.last_active_at).fromNow() : <span className="disabled">none</span>}
      </td>
      <td>
        {item.created_at ? moment(item.created_at).format("DD MMM YYYY") : ''}
      </td>
    </tr>
  )
}

export default Row;
