import {AdminHeader} from '@vas-common/ui-kit';
import React, {useContext, useEffect} from 'react';
// @ts-ignore
import logo from './logo.svg';

import {useNavigate} from "react-router-dom";

import routes from '../../utils/routes';
import {UserContext} from '../Hooks/UserProvider';

import './Header.scss'
const menu = routes.filter(item => item.isMenuItem)

const Header = () => {
  const {user, login} = useContext(UserContext);
  useEffect(() => {
    login()
  }, [])

  const navigate = useNavigate();
  return <AdminHeader
    logo={logo}
    bgColor={'#3D31FF'}
    user={user}
    settingsUrl={user?.currentProject?.settingsUrl}
    historyUrl={user?.currentProject?.historyUrl}
    onMenuClick={(url) => {
      navigate(url)
    }}
    // @ts-ignore
    menu={menu}
  />
}

export default Header;