import React, {useContext, useState, FC} from 'react';
import moment from "moment";
import {Button, Modal, notification, Spin} from "antd";
import {AdminDetailsHead, MoreDropDown} from "@vas-common/ui-kit";
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import {getFullName} from '../../../api/helper';
import {blockUser, getAuthToken, sendQrCodeEmail} from '../../../api/customers';
import {UserContext} from '../../../components/Hooks/UserProvider';
import UpdateUser from './UpdateUser';
import {TCustomer, TStatus} from '../../../api/Types/customers';
import {countryCodes} from '../../../utils/constants';

const confirm = Modal.confirm;

type Props = {
  user: TCustomer,
  loading: boolean,
  fetchUser: () => void,
  status: TStatus,
  handleChangeStatus: (id: number, condition: boolean) => void;
}

const Details: FC<Props> = ({user, loading, fetchUser, status, handleChangeStatus}) => {
  const {user: authUser} = useContext(UserContext);
  const [loginLoading, setLoading] = useState(false)

  const [isVisibleEditProfile, toggleEditProfile] = useState(false);

  const loginAs = async () => {
    setLoading(true)
    getAuthToken({id: user.id})
      .then(data => {
        const {access_token, device_token} = data;
        window.open(`https://${window.location.hostname.startsWith('admin-dev') ? 'dev.' : ''}yollaesim.com/?t=${Date.now()}&at=${access_token}&dt=${device_token}`,
          "_blank")
      }).finally(() => setLoading(false))
  }

  const conditionStatus = Object.keys(status).length !== 0;

  const actions = () => {
    return [
      {
        title: 'Send QR Code email',
        onClick: () => confirm({
          title: 'The email with the eSIM QR code and instruction will be sent to this user to email. Confirm, please.',
          okText: 'Send email',
          okType: 'primary',
          cancelText: 'No',
          onOk() {
            sendQrCodeEmail({id: user.id}).then(() => {
              notification.success({message: "Submitted"});
            })
          }
        })
      },
      {
        title: 'Edit Profile',
        onClick: () => toggleEditProfile(true)
      },
      {
        title: conditionStatus ? 'Unblock user' : 'Block user',
        onClick: () => handleChangeStatus(user.id, conditionStatus)
      },
      {
        title: 'Clear user data',
        onClick: () => confirm({
          title: 'Delete user personal data (name, email, ..) and deactivate packages. Confirm?',
          okText: 'Clear data',
          okType: 'danger',
          cancelText: 'No',
          onOk() {
            blockUser({id: user.id}).then(() => {
              notification.success({message: "User has been blocked"});
            })
              .then(() => window.location.reload());
          }
        })
      },
    ]
  }

  return (
    <Spin spinning={loading}>
      <UpdateUser
        user={user}
        handleClose={() => toggleEditProfile(false)}
        fetchUser={fetchUser}
        isVisible={isVisibleEditProfile}/>
      <AdminDetailsHead title={getFullName(user.last_name, user.first_name)}
                        buttons={<MoreDropDown actions={actions()}/>}
      >
        <table>
          <tbody>
          <tr>
            <td>Email:</td>
            <td><strong>{user?.email}</strong></td>
          </tr>
          <tr>
            <td>Country:</td>
            <td>{user.country ? `${getUnicodeFlagIcon(user.country)} ${countryCodes[user?.country] || ''}` :
              <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Language:</td>
            <td>
              {user.language}
            </td>
          </tr>
          {conditionStatus && (
            <tr>
              <td>Blockage:</td>
              <td style={{color: 'red'}}>Blocked by {Object.keys(status).map((el) => el)}</td>
            </tr>
          )}
          <tr>
            <td>Last active at:</td>
            <td>{user?.last_active_at ? moment(user?.last_active_at).format("DD MMM YYYY") :
              <span className="disabled">none</span>}</td>
          </tr>

          <tr>
            <td>Forward Account ID:</td>
            <td>{user?.fw_id_client_inst ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Registration date:</td>
            <td>{moment(user?.created_at).format("DD MMM YYYY")}</td>
          </tr>
          {user?.deleted_at ? <tr>
            <td>Deactivation date:</td>
            <td>{user?.deleted_at ? moment(user?.deleted_at).format("DD MMM YYYY") :
              <span className="disabled">none</span>}</td>
          </tr> : ''}
          <tr>
            <td>
              Login to user account
            </td>
            <td>
              <Button
                size={'small'}
                loading={loginLoading}
                type={'primary'}
                ghost
                onClick={(e) => {
                  e.preventDefault();
                  loginAs()
                }}>
                Login
              </Button>
            </td>
          </tr>
          </tbody>
        </table>
      </AdminDetailsHead>
    </Spin>
  )
}

export default Details;
