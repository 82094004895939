export const queryKeys = ['method',
  'type',
  'bcountry',
  'anumber',
  'fraudType',
  'bnumber',
  'from',
  'to',
  'limit',
  'page',
  'context',
  'clientId'
]

export const ucFirst = (str: string) => {
  if (!str) return str;
  return str[0].toUpperCase() + str.slice(1);
}

export const States: any = {
  available: 'Available',
  sold: 'Sold',
  installed: 'Installed',
  active: 'Active',
  removed: 'Removed',
  error: 'Error',
}

export const fixedNumber = (number: any,decimalPlace=2): string => Number(number).toFixed(decimalPlace)

export const mbToGb = (number: number, comma = 3) => number <= 0 ? 0 : (number / 1024).toFixed(comma)