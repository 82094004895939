import React from 'react';
import Row from './Row';
import {ThSorted} from '@vas-common/ui-kit';
import {TDevice} from '../../../../api/Types/devices';

const Index = (props: {
  data: TDevice[],
  isOrderColumn: boolean,
  withSorted?: boolean,
  orderColumn: string,
  onClick: (name: string) => any,
}) => {
  const {data,withSorted=true} = props;

  return (
    <table>
      <tbody>
      <tr>
        <th>Platform</th>
        <th>App Version</th>
        <th>Os version</th>
        {withSorted
          ?<ThSorted name={'created_at'} {...props}>
            Created at
          </ThSorted>
          :<th>
            Created at
          </th>
        }
        {withSorted
          ?<ThSorted name={'last_activity_at'} {...props}>
            Last active At
          </ThSorted>
          :<th>
            Last active At
          </th>
        }
      </tr>
      {data.length > 0 ? (
        data.map((item: any, idx: number) => <Row key={idx} item={item}/>)
      ) : (
        <tr>
          <th colSpan={5}>
            <div className={'empty'}>
              There are no records
            </div>
          </th>
        </tr>
      )}

      </tbody>
    </table>
  )
}

export default Index;
