import {httpClient} from './index';
import {TPayment, TPayments} from './Types/payments';
import {TEsim, TEsims, TSimCardState} from './Types/esims';
import {TDevice, TDevices} from './Types/devices';

export const fetchDevices = async (query: string): Promise<TDevices> => {
  return httpClient.get(`/admin/v1/devices${query}`)
}
export const fetchDeviceById = async ({id}: { id: number }): Promise<TDevice> => {
  return httpClient.get(`/admin/v1/devices/${id}`)
}

export const fetchEsims = async (query: string): Promise<TEsims> => {
  return httpClient.get(`/admin/v1/sim-cards${query}`)
}

export const fetchEsimById = async ({id}: { id: number }): Promise<TEsim> => {
  return httpClient.get(`/admin/v1/sim-cards/${id}`)
}

export const fetchPayments = async (query: string): Promise<TPayments> => {
  return httpClient.get(`/admin/v1/transactions${query}`)
}
export const fetchPaymentById = async ({id}: { id: number }): Promise<TPayment> => {
  return httpClient.get(`/admin/v1/transactions/${id}`)
}

export const fetchTransactionsPaidSum = async (params?: string): Promise<{ from: string, amount: number }> => {
  return httpClient.get(`/admin/v1/stats/transactions-paid-sum${params}`)
}

export const fetchTransactionsPaidDay = async (): Promise<{
  amount: string,
  from: Date
}> => {
  return httpClient.get(`/admin/v1/stats/transactions-paid-day`)
}

export const fetchSimCardsStates = async (): Promise<TSimCardState[]> => {
  return httpClient.get(`/admin/v1/stats/sim-cards-states`)
}
