import {Checkbox, Form, Input, Modal, notification, Select, Spin} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, {useState} from 'react';
import {updatePackageById} from '../../../api/packages';
import {countryCodes} from '../../../utils/constants';
import getCountryFlag from 'country-flag-icons/unicode';

const {Option} = Select;

const EditPackageForm = (props: { isVisible: boolean, handleClose: () => void, item: any, fetch: () => void }): JSX.Element => {
  const {handleClose, item, isVisible, fetch} = props;
  const initialValues = item;
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async () => {
    const values = await form.validateFields();
    setLoading(true);
    updatePackageById({id: item.id, body: {...values, position: item.position}}).then(() => {
      notification.success({message: `Package has been edited`});
      fetch();
      handleClose()
    }).finally(() => setLoading(false))
  };
  return <Modal
    width={400}
    title={'Edit Package'}
    visible={isVisible}
    onOk={() => form.submit()}
    okText={'Save'}
    okButtonProps={{disabled: isLoading}}
    onCancel={handleClose}
    destroyOnClose={true}
  >
    <Spin spinning={isLoading}>
      <Form initialValues={initialValues} form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name={'is_enabled'}
          valuePropName={'checked'}
        >
          <Checkbox>Is Enabled</Checkbox>
        </Form.Item>
        <Form.Item
          name={'name'}
          required
          label="Name"
          rules={[
            {
              required: true,
              message: 'Please input name',
            },
          ]}
        >
          <Input
            placeholder={`Name`}
          />
        </Form.Item>
        <Form.Item
          name={'slug'}
          required
          label="Slug"
          rules={[
            {
              required: true,
              message: 'Please input slug',
            },
          ]}
        >
          <Input
            placeholder={`Slug`}
          />
        </Form.Item>
        <Form.Item
          name={'description'}
          required
          label="Description"
        >
          <TextArea rows={4} placeholder="Description"/>
        </Form.Item>
        <Form.Item
          name={'countries'}
          required
          label="Countries"
        >
          <Select
            allowClear={true}
            mode="multiple"
            style={{minWidth: 150}}
            placeholder="Country"
            showSearch={true}
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {Object.keys(countryCodes).map((country: string) => (
              <Option defaultValue={country} key={country}>
                {getCountryFlag(country)} {country}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={'fw_id_balance'}
          required
          label="Balance id"
          rules={[
            {
              required: true,
              message: 'Please balance id',
            },
          ]}
        >
          <Input
            type={'number'}
            placeholder={`Balance id`}
          />
        </Form.Item>
        <Form.Item
          name={'fw_id_extension'}
          required
          label="Extension id"
          rules={[
            {
              required: true,
              message: 'Please extension id',
            },
          ]}
        >
          <Input
            type={'number'}
            placeholder={`Extension id`}
          />
        </Form.Item>

      </Form>
    </Spin>
  </Modal>
}

export default EditPackageForm;