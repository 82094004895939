import {AdminTable, Container} from '@vas-common/ui-kit';
import React, {useState} from 'react';
import useRequest from '../../../components/Hooks/useRequest';
import {fetchDevices} from '../../../api/other';
import {Pagination, PaginationProps} from 'antd';
import DevicesTable from '../../DevicesPage/Components/DevicesTable';
import up from '../../UsersPage/users.module.scss';
import qs from 'qs';

const DevicesBlock = ({userId}:{userId:number}) => {
  const [filters, setFilters] = useState<any>({
    filter: {
      customer_id: userId,
    },
    sort: {
      created_at:'desc'
    },
    limit: 10,
    page: 1,
  });

  const {loading} = useRequest(fetchDevices, `?${qs.stringify(filters)}`, (data) => setDevices(data), );

  const [devices, setDevices] = useState<{ data: any[], meta: any }>({
    data: [],
    meta: {},
  });

  const toggleSort = (selectedColumn: string) => {
    const currentColumn = filters.sort ? Object.keys(filters.sort)[0] : undefined;
    const isCurrentColumn = selectedColumn === currentColumn
    const sort = {
      [selectedColumn]: isCurrentColumn
        ? filters.sort[selectedColumn] === 'asc' ? 'desc' : 'asc'
        : 'desc'
    }
    setFilters({
      ...filters, sort, page: 1
    })
  }

  const onChange: PaginationProps['onChange'] = (page, sizePage) => {
    setFilters({...filters, page: page, limit: sizePage})
  };

  return devices?.meta?.total > 0 ? <Container>
      <AdminTable
        title={'Devices'}
        isPage={false}
        caption={loading ||
          devices.meta.total && `${devices.meta.total} found`}>
        <DevicesTable
          data={devices.data}
          withSorted={false}
orderColumn={Object.keys(filters.sort)[0]}
              isOrderColumn={(filters.sort[Object.keys(filters.sort)[0]]==='desc')}
          onClick={toggleSort}
        />
        {devices?.data?.length!=devices?.meta?.total ? <Pagination
          className={up.pagination}
          current={filters.page}
          onChange={onChange}
          showSizeChanger
          pageSize={filters.limit} total={devices?.meta?.total}/> :''}
      </AdminTable>
  </Container> : <></>
}
export default DevicesBlock;
