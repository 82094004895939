import React from 'react'
import ch from './chart.module.scss';
import {useNavigate} from 'react-router-dom';
import {Button} from 'antd';
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {UIHelpers} from '@vas-common/ui-kit';

const payments = {amount: '#00a400', count: '#ff8400'};
const CustomTooltip = (props: any) => {
  const {active, payload, label} = props;
  if (active && payload && payload.length) {
    return (
      <div style={{background: 'white', padding: '10px'}}>
        <p>{label}</p>
        <p
          style={{color: payments.amount}}>{payload[0].dataKey} : {UIHelpers.formatNumber(payload[0].value)} €</p>
      </div>
    );
  }

  return null;
};
const PaymentsChart = (props: {
  data: any,
  height?: number,
}): JSX.Element => {
  const {data, height = 150} = props;
  const navigator = useNavigate()

  return <div className={ch.chart}>
    <div className={ch.head}>
      <h3 className={ch.title}>Payments</h3>
      <div className={ch.controls}>

        <Button type="primary" onClick={() => navigator('/payments')}>
          Show all
        </Button>
      </div>

    </div>
    <ResponsiveContainer width="100%" height={height}>
      <BarChart
        data={data}
        maxBarSize={10}>
        <CartesianGrid stroke="#dedede" strokeDasharray="1 1"/>
        <YAxis tickFormatter={v => {
          return `${UIHelpers.formatNumber(v).toLocaleString()} €`;
        }}/>
        <XAxis dataKey={'date'} tick={{fontSize: 11}}/>
        <Tooltip content={(props) => <CustomTooltip active={props.active}
                                                    payload={props.payload}
                                                    label={props.label}/>}/>
        {<>
          <Bar dataKey={'Amount'} stackId="b" fill={payments.amount}/>
        </>}

      </BarChart>
    </ResponsiveContainer>

  </div>
}

export default PaymentsChart;
