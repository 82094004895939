import React, {FC} from "react";
import moment from "moment";
import {MoreDropDown, MoreIcon, Tags} from "@vas-common/ui-kit";
import {Link} from 'react-router-dom';

import {TCustomerPackage} from '../../../../api/Types/customers';
import {deleteCustomerPackageId} from '../../../../api/customers';
import {Modal, notification} from 'antd';
import { mbToGb } from "../../../../utils";

const confirm = Modal.confirm;

type Prop = {
  item: TCustomerPackage,
  refetch: () => void,
}

const Row: FC<Prop> = ({item, refetch}) => {
  const packageMenu = () => {
    const menu = [];
    if (item.status === 'active') {
      menu.push({
        title: 'Deactivate package',
        onClick: () => confirm({
          title: 'Are you sure want to deactivate this package from the user?',
          okText: 'Deactivate',
          okType: 'danger',
          cancelText: 'No',
          onOk() {
            deleteCustomerPackageId({customerId: item.customer_id, packageId: item.id}).then(() => {
              notification.success({message: "Deactivated"});
              refetch()
            })
          }
        })
      })
    }
    return menu
  }
  return (
    <tr>
      <td><Link className={'link'} to={`/packages/${item.package_id}`}>
        <strong>{item.name} {mbToGb(item.volume, 1)} GB</strong>
      </Link></td>

      <td>
        <span
          className={Number(item.volume) <= 0 ? 'disabled' : ''}>{mbToGb(item.volume)} GB</span>
      </td>
      <td>
        <span
          className={Number(item.remain) <= 0 ? 'disabled' : ''}>{mbToGb(item.remain)} GB</span>
      </td>
      <td>
        {item.last_usage_at ? moment(item.last_usage_at).format("DD MMM YYYY") : <span className="disabled">none</span>}
      </td>
      <td>
        {item.period_from ? moment(item.period_from).format("DD MMM YYYY") : ''}-
        {item.period_to ? moment(item.period_to).format("DD MMM YYYY") : ''}
      </td>
      <td>
        {item.status}
      </td>
      <td className={'options'}>
        <MoreDropDown actions={packageMenu()}><MoreIcon/></MoreDropDown>
      </td>
    </tr>
  )
}

export default Row;
