import {httpClient} from './index';
import {TCustomer, TCustomerPackages, TCustomerRegistrations, TCustomers, TStatus} from './Types/customers';
import {TOrders} from "./Types/orders";

export const fetchUsers = async (params: any):Promise<TCustomers> => {
  return httpClient.get(`/admin/v1/customers${params}`)
}
export const fetchUserById = async ({id}: { id: number }):Promise<TCustomer> => {
  return httpClient.get(`/admin/v1/customers/${id}`)
}

export const fetchUserStatus = async ({id}: { id: number }): Promise<TStatus> => {
  return httpClient.get(`/admin/v1/customers/${id}/blocks`)
}

export const postBlockUser = async ({id}: { id: number }): Promise<any> => {
  return httpClient.post(`/admin/v1/customers/${id}/block`)
}

export const postUnblockUser = async ({id}: { id: number }): Promise<any> => {
  return httpClient.post(`/admin/v1/customers/${id}/unblock`)
}

export const fetchCustomerPackage = async ({id}: { id: number }):Promise<TCustomerPackages> => {
  return httpClient.get(`/admin/v1/customers/${id}/packages`)
}

export const fetchCustomerOrders = async (query: string):Promise<TOrders[]> => {
  return httpClient.get(`/admin/v1/product-orders${query}`)
}
export const getAuthToken = async ({id}: { id: number }): Promise<{
  access_token: string, device_token: string
}> => {
  return httpClient.post(`/admin/v1/customers/${id}/access-token`)
}

export const blockUser = async ({id}: { id: number }): Promise<{
  access_token: string, device_token: string
}> => {
  return httpClient.post(`/admin/v1/customers/${id}/deactivate`)
}

export const sendQrCodeEmail = async ({id}: { id: number }): Promise<any> => {
  return httpClient.post(`/admin/v1/customers/${id}/sim-send-qr`)
}

export const deleteCustomerPackageId = async ({customerId,packageId}: { customerId:number,packageId: string }): Promise<any> => {
  return httpClient.delete(`/admin/v1/customers/${customerId}/packages/${packageId}`)
}

export const updateUser = async (id: number, body: any): Promise<any> => {
  return httpClient.put(`/admin/v1/customers/${id}`, body)
}

export const fetchCustomersActivity = async (params?:string):Promise<{from:string,count:number}> => {
  return httpClient.get(`/admin/v1/stats/customers-activity${params}`)
}

export const fetchCustomersRegistrations = async ():Promise<TCustomerRegistrations> => {
  return httpClient.get(`/admin/v1/stats/customers-registrations`)
}
