import React, {useEffect, useState} from 'react';
import {Pagination, PaginationProps, Spin} from 'antd';
import {AdminTable, Container, UIHelpers} from "@vas-common/ui-kit";

import Page from '../../components/Page';
import DevicesTable from './Components/DevicesTable';
import {fetchDevices} from '../../api/other';
import up from '../UsersPage/users.module.scss';
import qs from 'qs';
import {useLocation} from 'react-router';
import {LIMIT} from '../../utils/constants';
import {useLocationHook2} from '../../utils/hooks';

const Index = () => {
  const location = useLocation();
  const initialQuery=UIHelpers.getQueryParams(location.search)
  const {setParams:changeParams,state} = useLocationHook2({initialState: initialQuery});
  const [filters, setFilters] = useState<any>({
    sort: {
      created_at: 'desc'
    },
    limit: LIMIT,
    page: 1,
    ...state
  });

  const [devices, setDevices] = useState<any>({
    data: {},
    meta: {}
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
      setLoading(true)
      fetchDevices(`?${qs.stringify(filters)}`)
      .then(data => {
        setDevices(data);
      })
      .finally(() => setLoading(false));
    },
    [filters],
  )

  const toggleSort = (selectedColumn: string) => {
    const currentColumn = filters.sort ? Object.keys(filters.sort)[0] : undefined;
    const isCurrentColumn = selectedColumn === currentColumn
    const sort = {
      [selectedColumn]: isCurrentColumn
        ? filters.sort[selectedColumn] === 'asc' ? 'desc' : 'asc'
        : 'desc'
    }
    setFilters({
      ...filters, sort, page: 1
    })
  }

  const onChangePage: PaginationProps['onChange'] = (page, sizePage) => {
    setFilters({...filters, page: page, limit: sizePage})
  };

  useEffect(() => {
    changeParams(filters)
  }, [filters])

  return (
    <Page title={'Devices'}>
      <Container>
        <Spin spinning={loading}>
          <AdminTable
            title={'Devices'}
            isPage={true}
            caption={loading ||
              devices.meta.total && `${devices.meta.total} found`}>
            <DevicesTable
              data={devices.data}
              orderColumn={Object.keys(filters.sort)[0]}
              isOrderColumn={(filters.sort[Object.keys(filters.sort)[0]] === 'desc')}
              onClick={toggleSort}
            />
            {devices?.data?.length != devices?.meta?.total ? <Pagination
              className={up.pagination}
              current={filters.page}
              onChange={onChangePage}
              showSizeChanger
              pageSize={filters.limit} total={devices?.meta?.total}/> : ''}
          </AdminTable>
        </Spin>
      </Container>
    </Page>
  )
}

export default Index;
