import React from 'react';
import {useParams} from 'react-router-dom';
import Details from './components/Details';
import Page from '../../components/Page';
import {ucFirst} from '../../utils';
import EsimsBlock from './components/EsimsBlock';
import PackagesBlock from './components/PackagesBlock';
import useRequest from '../../components/Hooks/useRequest';
import {fetchUserById, fetchUserStatus, postBlockUser, postUnblockUser} from '../../api/customers';
import PaymentsBlock from './components/PaymentsBlock';
import DevicesBlock from './components/DevicesBlock';
// import OrdersBlock from "./components/OrdersBlock";
import {getFullName} from '../../api/helper';

const Index = () => {
  const {userId} = useParams();
  const {data: user, loading: userLoading, fetch} = useRequest(fetchUserById, {id: userId});

  const {
    data: statusUser,
    loading: statusLoading,
    fetch: fetchStatus,
    setState,
  } = useRequest(fetchUserStatus, {id: userId}, undefined, [], {});

  const isLoading = (userLoading || statusLoading);

  const handleChangeStatus = (id: number, condition: boolean) => {
    const request = condition ? postUnblockUser({id}) : postBlockUser({id});
    setState({
      data: statusUser,
      loading: true,
      error: undefined
    });
    request.then(() => {
      fetchStatus({id: userId})
    })
  }

  return (
    <Page title={`${ucFirst((getFullName(user.last_name, user.first_name)))} ${userId}`}>
      <div style={{maxWidth: '900px', margin: '0 auto'}}>

        <Details
          user={user}
          status={statusUser}
          loading={isLoading}
          handleChangeStatus={handleChangeStatus}
          fetchUser={() => fetch({id: userId})}
        />
        <EsimsBlock userId={Number(userId)}/>
        <PackagesBlock userId={Number(userId)}/>
        <PaymentsBlock userId={Number(userId)}/>
        <DevicesBlock userId={Number(userId)}/>
        {/*<OrdersBlock userId={Number(userId)}/>*/}
      </div>
    </Page>
  )
}

export default Index;
