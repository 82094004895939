import React, {useState} from 'react';
import moment from "moment";
import {notification, Spin} from "antd";
import {AdminDetailsHead, MoreDropDown} from "@vas-common/ui-kit";
import s from './details.module.scss'
import {TPackage} from '../../../api/Types/packages';
import {CheckIcon} from '../../../components/Icons';
import EditPackageForm from './EditPackageForm';

const Details = ({item, loading, fetch}: { item: TPackage, loading: boolean, fetch: () => void }) => {
  const [isVisibleEditPackage, toggleEditPackage] = useState(false);

  const actions = () => {
    return [
      {
        title: 'Edit Package',
        onClick: () => toggleEditPackage(true)
      },
    ]
  }
  return (
    <>
      <EditPackageForm
        item={item}
        handleClose={() => toggleEditPackage(false)}
        fetch={fetch}
        isVisible={isVisibleEditPackage}/>

      <Spin spinning={loading}>
        <AdminDetailsHead title={item.name}
                          buttons={<MoreDropDown actions={actions()}/>}>
          <table>
            <tbody>
            <tr>
              <td>Status:</td>
              <td><CheckIcon enable={item.is_enabled}/><span
                className={s.packageStatus}>{item.is_enabled ? 'Enabled' : 'Disabled'}</span></td>
            </tr>
            <tr>
              <td>Slug:</td>
              <td>{item.slug}</td>
            </tr>
            <tr>
              <td>Description:</td>
              <td>{item.description}</td>
            </tr>
            <tr>
              <td>Countries:</td>
              <td>{item.countries ? item.countries.join(', ') : <span className="disabled">none</span>}</td>
            </tr>
            <tr>
              <td>Balance id:</td>
              <td>{item.fw_id_balance}</td>
            </tr>
            <tr>
              <td>Extension id:</td>
              <td>{item.fw_id_extension}</td>
            </tr>
            <tr>
              <td>Created at:</td>
              <td>{item?.created_at ? moment(item?.created_at).format("DD MMM YYYY") :
                <span className="disabled">none</span>}</td>
            </tr>
            <tr>
              <td>Updated at:</td>
              <td>{item?.updated_at ? moment(item?.updated_at).format("DD MMM YYYY") :
                <span className="disabled">none</span>}</td>
            </tr>
            </tbody>
          </table>
        </AdminDetailsHead>
      </Spin>
    </>)
}

export default Details;
