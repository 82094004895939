import {TPackage, TPackages, TPlan} from './Types/packages';
import {httpClient} from './index';

export const fetchPackages = async (query: string): Promise<TPackages> => {
  return httpClient.get(`/admin/v1/packages${query}`)
}

export const fetchPackageById = async ({id}: { id: number }): Promise<TPackage> => {
  return httpClient.get(`/admin/v1/packages/${id}`)
}


export const updatePackageById = async ({id,body}: { id: number,body:TPlan }): Promise<TPackage> => {
  return httpClient.put(`/admin/v1/packages/${id}`,body)
}

export const fetchPlans = async (query: string): Promise<TPlan[]> => {
  return httpClient.get(`/admin/v1/plans${query}`)
}

export const createPlan = async (body: {
  package_id: TPlan['package_id'],
  volume: TPlan['volume'],
  days: TPlan['days'],
  currency: TPlan['currency'],
  is_preselected: TPlan['is_preselected'],
}): Promise<TPlan[]> => {
  return httpClient.post(`/admin/v1/plans`, body)
}

export const deletePlan = async (id:any) => {
  return httpClient.delete(`/admin/v1/plans/${id}`)
}
export const enablePlan = async (id:any) => {
  return httpClient.post(`/admin/v1/plans/${id}/enable`)
}
export const disablePlan = async (id:any) => {
  return httpClient.post(`/admin/v1/plans/${id}/disable`)
}