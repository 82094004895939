import React from "react";
import {UIHelpers, SummaryBlock} from "@vas-common/ui-kit";
import c from './cards.module.scss'

const Index = ({
                 simLoading,
                 activitiesMonthLoading,
                 activitiesDayLoading,
                 paidSumDayLoading,
                 paidSumMonthLoading,
                 simCardsStates,
                 activitiesDay = {count: 0},
                 activitiesMonth = {count: 0},
                 transactionsPaidSumDay = {amount: 0},
                 transactionsPaidSumMonth = {amount: 0},
               }: {
  simLoading: boolean
  activitiesMonthLoading: boolean
  activitiesDayLoading: boolean
  paidSumDayLoading: boolean
  paidSumMonthLoading: boolean
  simCardsStates: {
    active: number,
    installed: number
    available: number
  }
  activitiesDay: { count: number }
  activitiesMonth: { count: number }
  transactionsPaidSumDay: { amount: number }
  transactionsPaidSumMonth: { amount: number }
}) => {

  return (
    <div className={c.cards}>
      <SummaryBlock title={'Available eSims'}
                    loading={simLoading}
                    value={<span className={c.blue}><span
                      className={`strong`}>{UIHelpers.formatNumber(simCardsStates?.available)}</span></span>}
                    caption={'Total Available'}/>
      <SummaryBlock title={'Paid eSims'} value={
        <span>
          <span className={`strong ${c.green}`}>
            {UIHelpers.formatNumber(simCardsStates?.active)}</span>
            <span className={'divide'}> / </span>
          {UIHelpers.formatNumber(simCardsStates?.installed)}</span>}
                    loading={simLoading}
                    caption={'Active / Installed'}/>

      <SummaryBlock title={'Users'}
                    value={<span className={`${c.green} ${activitiesMonth?.count == 0 ? c.gray : ''}`}><span
                      className={`strong ${activitiesDay?.count == 0 ? c.gray : ''}`}>{activitiesDay?.count}</span><span
                      className={'divide'}> / </span>{UIHelpers.formatNumber(activitiesMonth?.count)}</span>}
                    loading={activitiesMonthLoading && activitiesDayLoading}
                    caption={'DAU / MAU'}/>

      <SummaryBlock title={'Payments'}
                    value={<span><span
                      className={`strong ${Number(transactionsPaidSumDay?.amount) == 0 ? c.gray : ''}`}>€{transactionsPaidSumDay?.amount}</span><span
                      className={'divide'}> / </span>€{UIHelpers.formatNumber(Math.round((transactionsPaidSumMonth?.amount)))}</span>}
                    loading={paidSumDayLoading && paidSumMonthLoading}
                    caption={'DAY / MONTH'}/>
    </div>

  )
}

export default Index;
