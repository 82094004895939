import React, {FC} from "react";
import {MoreDropDown, MoreIcon, Tags} from "@vas-common/ui-kit";
import s from './payments.module.scss'
import {CheckIcon} from '../../../../components/Icons';
import {TPlan} from '../../../../api/Types/packages';
import {Modal, notification} from 'antd';
import {deletePlan, disablePlan, enablePlan} from '../../../../api/packages';
import { mbToGb } from "../../../../utils";

const confirm = Modal.confirm;
type Prop = {
  item: TPlan
  fetch: () => void
}

export const Tag = ({status}: { status: string }) => <Tags className={`${s.status} ${s[`status-${status}`]}`} values={[{
  name: status
}]}/>

const Row: FC<Prop> = ({item, fetch}) => {
  const planMenu = () => ([
    {
      disabled: !item.is_enabled,
      title: 'Disable',
      onClick: () => confirm({
        title: 'Are you sure want to disable this plan for the package?',
        okText: 'Disable',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          disablePlan(item.id).then(() => {
            notification.success({message: "Disabled"});
            fetch()
          })
        }
      })
    },
    {
      title: 'Enable',
      disabled: item.is_enabled,
      onClick: () => confirm({
        title: 'Are you sure want to enable this plan for the package?',
        okText: 'Enable',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          enablePlan(item.id).then(() => {
            notification.success({message: "Enabled"});
            fetch()
          })
        }
      })
    },
    {
      title: `Delete`,
      disabled: item.sales_count !== 0,
      onClick: () => confirm({
        title: 'Are you sure want to delete this plan from the package?',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          deletePlan(item.id).then(() => {
            notification.success({message: "Deleted"});
            fetch()
          })
        }
      })
    }
  ])
  return (
    <tr>
      <td><CheckIcon enable={item.is_enabled}/></td>
      <td>
        <span
          className={Number(item.volume) <= 0 ? 'disabled' : ''}>{mbToGb(item.volume)} GB</span>
      </td>
      <td>
        {item.days} days
      </td>
      <td>{item.price ? `${item.price} ${item.currency}` : <span className="disabled">none</span>}</td>
      <td>{item.sales_count || <span className="disabled">0</span>}</td>
      <td>{item.active_count || <span className="disabled">0</span>}</td>
      <td className={'options'}>
        <MoreDropDown actions={planMenu()}><MoreIcon/></MoreDropDown>
      </td>
    </tr>
  )
}

export default Row;
