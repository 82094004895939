import React, {FC} from "react";
import moment from "moment";
import {Tags} from "@vas-common/ui-kit";
import s from './payments.module.scss'
import {Link} from 'react-router-dom';
import {CheckIcon} from '../../../../components/Icons';
import {TPackage} from '../../../../api/Types/packages';
type Prop = {
  item: TPackage
  onClick?: () => void
  clients?: any
}

export const Tag = ({status}: { status: string }) => <Tags className={`${s.status} ${s[`status-${status}`]}`} values={[{
  name: status
}]}/>

const Row: FC<Prop> = ({item}) => {
  return (
    <tr>
      <td><Link className={'link'} to={`/packages/${item.id}`}><strong>{`${item.name}`}</strong></Link></td>
      <td><CheckIcon enable={item.is_enabled}/></td>
      <td>{item.countries.length}</td>
      <td>{item.fw_id_balance??<span className="disabled">none</span>}</td>
      <td>{item.fw_id_extension??<span className="disabled">none</span>}</td>
      <td>{item.slug}</td>
      <td>
        {item.updated_at ? moment(item.updated_at).fromNow() : <span className="disabled">none</span>}
      </td>
    </tr>
  )
}

export default Row;
