import {AdminTable, Container} from '@vas-common/ui-kit';
import React, {useState} from 'react';
import useRequest from '../../../components/Hooks/useRequest';
import {fetchPlans} from '../../../api/packages';
import PlansTable from './PlansTable';
import qs from 'qs';
import {Button, Spin} from 'antd';
import CreatePackagePlanForm from './CreatePackagePlanForm';
import {TPlan} from '../../../api/Types/packages';

const PlansBlock = ({package_id}: { package_id: TPlan['package_id'] }) => {

  const [isVisibleCreatePlan, toggleCreatePlan] = useState(false);
  const queryPlans = `?${qs.stringify({filter: {package_id}})}`
  const {
    data: plans,
    loading,
    fetch: fPlans
  } = useRequest(fetchPlans, queryPlans, undefined, [], {
    data: []
  });

  return <Container>
    <CreatePackagePlanForm
      package_id={package_id}
      handleClose={() => toggleCreatePlan(false)}
      fetch={() => fetch(queryPlans)}
      isVisible={isVisibleCreatePlan}/>
    <Spin spinning={loading}>
      <AdminTable
        title={'Plans'}
        isPage={false}
        buttons={<Button type="primary" onClick={() => toggleCreatePlan(true)}>Add Plan</Button>}
        caption={loading ||
          plans.data.length > 0 && `${plans.data.length} found`}>
        <PlansTable
          data={plans.data}
          fetch={() => fPlans(queryPlans)}
        />
      </AdminTable>
    </Spin>
  </Container>
}
export default PlansBlock;