import React from 'react';
import Row from './Row';
import {ThSorted} from '@vas-common/ui-kit';
import {TPackage} from '../../../../api/Types/packages';

const Index = (props: {
  data: TPackage[],
  isOrderColumn: boolean,
  orderColumn: string,
  onClick: (name: string) => any,
}) => {
  const {data} = props;

  return (
    <table>
      <tbody>
      <tr>
        <th>Name</th>
        <th>Status</th>
        <th>Countries</th>
        <th>Balance id</th>
        <th>Extension id</th>
        <th>slug</th>
        <ThSorted name={'updated_at'} {...props}>
          Updated At
        </ThSorted>
      </tr>
      {data.length > 0 ? (
        data.map((item: any, idx: number) => <Row key={idx} item={item}/>)
      ) : (
        <tr>
          <th colSpan={6}>
            <div className={'empty'}>
              There are no records
            </div>
          </th>
        </tr>
      )}
      </tbody>
    </table>
  )
}

export default Index;
