import React, {createContext} from "react";
import {useState} from "react";
import {me} from '../../api';

type UserType = {
  email?: string
  role?: string;
  currentProject?:{
    id:number,
    name:string,
    historyUrl?:string,
    settingsUrl?:string,
  }
};

interface UserContextInterface {
  user: UserType;
  login: () => void;
}

export const UserContext = createContext<UserContextInterface>({
  user: {},
  login: () => {
  },
});

export const UserProvider = ({children}: { children: any }): any => {
  const [user, setUser] = useState({});

  const login = (): any => {
    me().then(user => {
      setUser({...user, role: user?.role?.name || ''})
    })
  };

  return (
    <UserContext.Provider value={{
      user,
      login,
    }}>
      {children}
    </UserContext.Provider>
  );
}