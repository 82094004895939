import {Form, Input, Modal, notification, Select, Spin} from 'antd';
import getCountryFlag from 'country-flag-icons/unicode';
import React, {useState} from 'react';
import {updateUser} from '../../../api/customers';
import {countryCodes} from '../../../utils/constants';

const UpdateUser = (props: { isVisible: boolean, handleClose: () => void, user: any, fetchUser: () => void }): JSX.Element => {
  const {handleClose, user, isVisible, fetchUser} = props;
  const initialValues = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    country: user.country,
  }
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async () => {
    const values = await form.validateFields();
    setLoading(true);
    updateUser(user.id, {...values, language: user.language}).then(() => {
      notification.success({message: `User has been update`});
      fetchUser();
      handleClose()
    }).finally(() => setLoading(false))
  };
  const {Option} = Select;
  return <Modal
    width={400}
    title={'Edit Profile'}
    visible={isVisible}
    onOk={() => form.submit()}
    okText={'Save'}
    okButtonProps={{disabled: isLoading}}
    onCancel={handleClose}
    destroyOnClose={true}
  >
    <Spin spinning={isLoading}>
      <Form initialValues={initialValues} form={form} onFinish={onFinish} layout="vertical"
            id={'editProfile'}>
        <Form.Item
          name={'email'}
          required
          label="Email"
          rules={[
            {
              required: true,
              message: 'Please input email',
            },
          ]}
        >
          <Input
            placeholder={`Email`}
          />
        </Form.Item>
        <Form.Item
          name={'first_name'}
          required
          label="First name"
          rules={[
            {
              required: true,
              message: 'Please input first name',
            },
          ]}
        >
          <Input
            placeholder={`First name`}
          />
        </Form.Item>
        <Form.Item
          name={'last_name'}
          required
          label="Last name"
          rules={[
            {
              required: true,
              message: 'Please input last name',
            },
          ]}
        >
          <Input
            placeholder={`Last name`}
          />
        </Form.Item>

        <Form.Item label="Country" name={'country'}>
          <Select className={'select'} size={'large'}>
            {
              Object.keys(countryCodes)
              .map(item =>
                <Option className={'item'} style={{}}
                        size={'small'}
                        key={item}
                        value={item}>{countryCodes[item]} {getCountryFlag(item)}</Option>)}
          </Select>
        </Form.Item>
      </Form>
    </Spin>
  </Modal>
}

export default UpdateUser;