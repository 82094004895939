import React, {FC, useCallback, useState} from "react";
import moment from "moment";
import {Button, DatePicker, Input, Select, Space} from 'antd';

import s from './filters.module.scss';

import {countryCodes, getCountryName} from "../../../../utils/constants";

import {getAuthWithName, ssoAuthMethod} from '../../../../utils/table';
import {debounce} from "lodash";
import {downloadCsv} from '../../../../api';
import {DownloadOutlined} from '@ant-design/icons';
import {queryObjectToString} from '../../../../api/helper';

const {Option} = Select;
const {RangePicker} = DatePicker;

type Props = {
  onChangeFilter: (field: keyof any, defaultValue: any) => void,
  filters: any,
}

const Filters: FC<Props> = ({onChangeFilter, filters}) => {
  const [downloadLoader, setDownloadLoader] = useState(false)
  const download = () => {
    setDownloadLoader(true)
    downloadCsv('customers',queryObjectToString(filters)).finally(() => setDownloadLoader(false));
  }
  const debouncedSearch = useCallback(
    debounce((name,nextValue) => onChangeFilter(name,nextValue), 500),
    [],
  );

  const handleSearch = (event:any) => {
    event.persist();
    const { value: nextValue,name } = event.target;
    debouncedSearch(name,nextValue);
  };
  return <Space size={'small'} className={s.filters}>
    <Input
      style={{width: '225px'}}
      allowClear
      placeholder="Search by email, name"
      defaultValue={filters?.search}
      name={'search'}
      onChange={handleSearch}
    />
    <Select
      allowClear={true}
      style={{minWidth: 150}}
      placeholder="All country"
      onChange={(e: string) => onChangeFilter("country", e)}
      showSearch={true}
      defaultValue={filters?.filter?.country}
      filterOption={(input, option: any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {Object.keys(countryCodes).map((country: string) => (
        <Option defaultValue={country} key={country}>
          {getCountryName(country)}
        </Option>
      ))}
    </Select>
    <Select
      allowClear={true}
      style={{minWidth: 150}}
      defaultValue={filters?.filter?.auth_with}
      placeholder="Auth With"
      onChange={(e: string) => onChangeFilter("auth_with", e)}
      showSearch={true}
      filterOption={(input, option: any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {Object.keys(ssoAuthMethod).map((item: any) => (
        <Option value={item} key={item}>
          {getAuthWithName(item)}
        </Option>
      ))}
    </Select>
    <Select
      allowClear
      defaultValue={filters?.filter?.has_active_esim}
      style={{width: '120px'}}
      placeholder="With eSim"
      onChange={(e: string) => onChangeFilter("has_active_esim", e)}
    >
      <Option value={'true'} key={'2'}>
        Yes
      </Option>
      <Option value={'false'} key={'1'}>
        No
      </Option>
    </Select>
    <Select
      allowClear
      defaultValue={filters?.filter?.has_active_package}
      style={{width: '120px'}}
      placeholder="With package"
      onChange={(e: string) => onChangeFilter('has_active_package', e)}
    >
      <Option value={'true'} key={'2'}>
        Yes
      </Option>
      <Option value={'false'} key={'1'}>
        No
      </Option>
    </Select>
    <RangePicker
      allowClear
      placeholder={['Reg from', 'To']}
      format={['YYYY-MM-DD', 'YYYY-MM-DD']}
      defaultValue={filters?.filter?.created_at?.from && filters?.filter?.created_at?.to ? [moment(filters[filters?.filter?.created_at?.from]), moment(filters?.filter?.created_at?.to)] : undefined}
      onChange={(e: any) => {
        if (e) {
          const [from, to] = e;
          onChangeFilter("created_at", {
            from: moment(from).format('YYYY-MM-DD 00:00:00'),
            to: moment(to).format('YYYY-MM-DD 23:59:59')
          })
        } else {
          onChangeFilter("created_at", undefined)
        }
      }
      }
    />
    <Button
      onClick={() => download()}
      icon={<DownloadOutlined/>}
      loading={downloadLoader}
    >
      CSV
    </Button>
  </Space>
}

export default Filters;
