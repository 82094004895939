import React, {useEffect, useState} from 'react';
import {Pagination, PaginationProps, Spin} from 'antd';
import {AdminTable, Container, UIHelpers} from "@vas-common/ui-kit";

import Page from '../../components/Page';
import PaymentsTable from './Components/PaymentsTable';
import {fetchPayments} from '../../api/other';
import up from '../UsersPage/users.module.scss';
import qs from 'qs';
import {useLocation} from 'react-router';
import {LIMIT} from '../../utils/constants';
import Filters from './Components/Filters';
import {useLocationHook2} from '../../utils/hooks';

const Index = () => {
  const location = useLocation();
  const initialQuery = UIHelpers.getQueryParams(location.search)
  const {setParams: changeParams, state} = useLocationHook2({initialState: initialQuery});

  const [filters, setFilters] = useState<any>({
    sort: {
      created_at: 'desc'
    },
    filter: {
      status: '2'
    },
    limit: LIMIT,
    page: 1,
    ...state
  });

  const [payments, setPayments] = useState<any>({
    data: {},
    meta: {}
  });
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeFilter = (field: keyof any, value: string | any) => {
    if (field === 'search')
      filters.search = value;
    else
      filters.filter[field] = value;
    setFilters({...filters, page: 1})
  }
  useEffect(
    () => {
      setLoading(true)
      fetchPayments(`?${qs.stringify(filters)}`).then(data => {
        setPayments(data);
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      });
    }, [filters]
  )

  const toggleSort = (selectedColumn: string) => {
    const currentColumn = filters.sort ? Object.keys(filters.sort)[0] : undefined;
    const isCurrentColumn = selectedColumn === currentColumn
    const sort = {
      [selectedColumn]: isCurrentColumn
        ? filters.sort[selectedColumn] === 'asc' ? 'desc' : 'asc'
        : 'desc'
    }
    setFilters({
      ...filters, sort, page: 1
    })
  }

  const onChangePage: PaginationProps['onChange'] = (page, sizePage) => {
    setFilters({...filters, page: page, limit: sizePage})
  };

  useEffect(() => {
    changeParams(filters)
  }, [filters])
  return (
    <Page title={'Payments'}>
      <Container>
        <Spin spinning={loading}>
          <AdminTable
            title={'Payments'}
            isPage={true}
            caption={loading ||
              payments.meta.total && `${payments.meta.total} found`}>
            <Filters onChangeFilter={onChangeFilter} filters={filters}/>
            <PaymentsTable
              sortEnable={true}
              data={payments.data}
              orderColumn={Object.keys(filters.sort)[0]}
              isOrderColumn={(filters.sort[Object.keys(filters.sort)[0]] === 'desc')}
              onClick={toggleSort}
            />
            {payments?.data?.length != payments?.meta?.total ? <Pagination
              className={up.pagination}
              current={filters.page}
              onChange={onChangePage}
              showSizeChanger
              pageSize={filters.limit} total={payments?.meta?.total}/> : ''}
          </AdminTable>
        </Spin>
      </Container>
    </Page>
  )
}

export default Index;
