import React from 'react';
import {useParams} from 'react-router-dom';
import Details from './components/Details';
import Page from '../../components/Page';
import useRequest from '../../components/Hooks/useRequest';
import { fetchEsimById}  from '../../api/other';

const Index = () => {
  const {esimId} = useParams();
  const {data: item, loading} = useRequest(fetchEsimById, {id: esimId});
  return (
    <Page title={`eSIM ${item.iccid||''}`} className={'esim-detail'}>
      <div style={{maxWidth: '900px', margin: '0 auto'}}>
        <Details item={item} loading={loading}/>
      </div>
    </Page>
  )
}

export default Index;
