import React, {FC, useCallback, useState} from "react";
import moment from "moment";
import {Button, DatePicker, Input, Select, Space} from 'antd';

import s from './filters.module.scss';
import { States} from "../../../../utils";
import {debounce} from 'lodash';
import {downloadCsv} from '../../../../api';
import {queryObjectToString} from '../../../../api/helper';
import {DownloadOutlined} from '@ant-design/icons';

const {Option} = Select;
const {RangePicker} = DatePicker;

type Props = {
  onChangeFilter: (field: keyof any, value: any) => void,
  filters: any,
}

const Filters: FC<Props> = ({onChangeFilter, filters}) => {
  const [downloadLoader, setDownloadLoader] = useState(false)
  const download = () => {
    setDownloadLoader(true)
    downloadCsv('sim-cards',queryObjectToString(filters)).finally(() => setDownloadLoader(false));
  }
  const debouncedSearch = useCallback(
    debounce((name,nextValue) => onChangeFilter(name,nextValue), 500),
    [],
  );

  const handleSearch = (event:any) => {
    event.persist();
    const { value: nextValue,name } = event.target;
    debouncedSearch(name,nextValue);
  };
  return <Space size={'small'} className={s.filters}>
    <Input
      style={{width: '225px'}}
      allowClear
      placeholder="Search"
      defaultValue={filters["search"]}
      name={'search'}
      onChange={handleSearch}
    />
    <Select
      defaultValue={filters?.filter?.state?filters?.filter?.state.split(','):undefined}
      allowClear
      style={{minWidth:'120px',maxWidth: '520px'}}
      mode="multiple"
      placeholder="Any state"
      onChange={(e: any) => {
        onChangeFilter('state', e.length?e.join(','):undefined)
      }}
    >
      {Object.keys(States).map((item: any) => (
        <Option value={item} key={item}>
          {States[item]}
        </Option>
      ))}
    </Select>
    <RangePicker
      allowClear
      placeholder={['Reg from', 'To']}
      format={['YYYY-MM-DD', 'YYYY-MM-DD']}
      defaultValue={filters?.filter?.created_at?.from && filters?.filter?.created_at?.to ? [moment(filters[filters?.filter?.created_at?.from]), moment(filters?.filter?.created_at?.to)] : undefined}
      onChange={(e: any) => {
        if (e) {
          const [from, to] = e;
          onChangeFilter("created_at", {
            from: moment(from).format('YYYY-MM-DD 00:00:00'),
            to: moment(to).format('YYYY-MM-DD 23:59:59')
          })
        } else {
          onChangeFilter("created_at", undefined)
        }
      }
      }
    />
    <Button
      onClick={() => download()}
      icon={<DownloadOutlined/>}
      loading={downloadLoader}
    >
      CSV
    </Button>
  </Space>
}

export default Filters;
