import React from 'react';
import moment from "moment";
import {Spin} from "antd";
import {AdminDetailsHead} from "@vas-common/ui-kit";
import {TPayment} from '../../../api/Types/payments';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

const Details = ({item, loading}: { item: TPayment, loading: boolean }) => {
  return (
    <Spin spinning={loading}>
      <AdminDetailsHead title={item.id}>
        <table>
          <tbody>
          <tr>
            <td>Id:</td>
            <td>{item.id}</td>
          </tr>
          <tr>
            <td>Currency:</td>
            <td>{item.currency}</td>
          </tr>
          <tr>
            <td>Customer Country:</td>
            <td>{item.customer_country ? `${getUnicodeFlagIcon(item.customer_country)} ${item.customer_country}` :
              <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Customer id:</td>
            <td>{item.customer_id ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Customer email:</td>
            <td>{item.customer_email ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Customer uuid</td>
            <td>{item.customer_uuid ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Gateway</td>
            <td>{item.gateway?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Paid at:</td>
            <td>{item?.paid_at ? moment(item?.paid_at).format("DD MMM YYYY") :
              <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Pay Amount</td>
            <td>{item.pay_amount?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Pay total</td>
            <td>{item.pay_total?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Pay vat</td>
            <td>{item.pay_vat?? <span className="disabled">none</span>}</td>
          </tr>

          <tr>
            <td>Refund at:</td>
            <td>{item?.refunded_at ? moment(item?.refunded_at).format("DD MMM YYYY") :
              <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Refund Amount</td>
            <td>{item.refund_amount?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Refund total</td>
            <td>{item.refund_total?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Refund vat</td>
            <td>{item.refund_vat?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{item.status?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Updated at:</td>
            <td>{item?.updated_at ? moment(item?.updated_at).format("DD MMM YYYY") :
              <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Vat percent</td>
            <td>{item.vat_percent?? <span className="disabled">none</span>}</td>
          </tr>
          </tbody>
        </table>
      </AdminDetailsHead>
    </Spin>
  )
}

export default Details;
