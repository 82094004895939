import {AdminTable, Container} from '@vas-common/ui-kit';
import React, {useState} from 'react';
import PackagesTable from './PackagesTable';
import useRequest from '../../../components/Hooks/useRequest';
import {fetchCustomerPackage} from '../../../api/customers';

const PackageBlock = ({userId}: { userId: number }) => {

  const {loading,fetch:refetch} = useRequest(fetchCustomerPackage, {id: userId}, (data) => setPackages(data));

  const [packages, setPackages] = useState<{ data: any[], meta: any }>({
    data: [],
    meta: {},
  });

  return packages?.data.length > 0 ? <Container>
    <AdminTable
      title={'Packages'}
      isPage={false}
      caption={loading ||
        packages.data.length > 0 && `${packages.data.length} found`}>
      <PackagesTable
        data={packages.data}
        refetch={()=>refetch({id: userId})}
      />
    </AdminTable>
  </Container> : <></>
}
export default PackageBlock;