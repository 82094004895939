import React from 'react';
import Row from './Row';
import e from './esim.module.scss'
import {ThSorted} from '@vas-common/ui-kit';
import {TEsim} from '../../../../api/Types/esims';

const Index = (props: {
  data: TEsim[],
  isOrderColumn: boolean,
  withSorted?: boolean,
  orderColumn: string,
  onClick: (name: string) => any,
}) => {
  const {data, withSorted=true} = props;

  return (
    <table>
      <tbody>
      <tr>
        <th>ICCID</th>
        <th>IMSI</th>
        <th className={e.status}>State</th>
        <th className={e.techStatus}>Tech Status</th>
        <th>Owned by</th>
        {withSorted
          ? <ThSorted name={'assigned_at'} {...props}>
            Paid at
          </ThSorted>
          : <th>Paid at</th>
        }
        {withSorted
          ? <ThSorted name={'activated_at'} {...props}>
            Installed At
          </ThSorted>
          : <th>Installed At</th>
        }
        {withSorted
          ? <ThSorted name={'created_at'} {...props}>
            Created at
          </ThSorted>
          : <th>Created at</th>
        }
      </tr>
      {data.length > 0 ? (
        data.map((item: any, idx: number) => <Row key={idx} item={item}/>)
      ) : (
        <tr>
          <th colSpan={8}>
            <div className={'empty'}>
              There are no records
            </div>
          </th>
        </tr>
      )}

      </tbody>
    </table>
  )
}

export default Index;
