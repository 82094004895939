import {AdminTable, Container} from '@vas-common/ui-kit';
import React, {useState} from 'react';
import useRequest from '../../../components/Hooks/useRequest';
import {fetchPayments} from '../../../api/other';
import {Pagination, PaginationProps} from 'antd';
import qs from 'qs';
import up from '../../UsersPage/users.module.scss';
import PaymentsTable from './PaymentsTable';

const PaymentsBlock = ({userId}: { userId: number }) => {
  const [filters, setFilters] = useState<any>({
    filter: {
      customer_id: userId,
    },
    sort: {
      created_at: 'desc'
    },
    limit: 10,
    page: 1,
  });

  const {loading} = useRequest(fetchPayments, `?${qs.stringify(filters)}`, (data) => setPayments(data),);

  const [payments, setPayments] = useState<{ data: any[], meta: any }>({
    data: [],
    meta: {},
  });

  const toggleSort = (selectedColumn: string) => {
    const currentColumn = filters.sort ? Object.keys(filters.sort)[0] : undefined;
    const isCurrentColumn = selectedColumn === currentColumn
    const sort = {
      [selectedColumn]: isCurrentColumn
        ? filters.sort[selectedColumn] === 'asc' ? 'desc' : 'asc'
        : 'desc'
    }
    setFilters({
      ...filters, sort, page: 1
    })
  }

  const onChangePage: PaginationProps['onChange'] = (page, sizePage) => {
    setFilters({...filters, page: page, limit: sizePage})
  };
  return payments?.meta?.total > 0 ? <Container>
    <AdminTable
      title={'Payments'}
      isPage={false}
      caption={loading ||
        payments.meta.total && `${payments.meta.total} found`}>
      <PaymentsTable
        data={payments.data}
        orderColumn={Object.keys(filters.sort)[0]}
        isOrderColumn={(filters.sort[Object.keys(filters.sort)[0]] === 'desc')}
        onClick={toggleSort}
      />
      {payments?.data?.length != payments?.meta?.total ? <Pagination
        className={up.pagination}
        current={filters.page}
        onChange={onChangePage}
        showSizeChanger
        pageSize={filters.limit} total={payments?.meta?.total}/> : ''}
    </AdminTable>
  </Container> : <></>
}
export default PaymentsBlock;