import React, {FC, useCallback, useState} from "react";
import moment from "moment";
import {Button, DatePicker, Select, Space} from 'antd';

import s from './filters.module.scss';
import {debounce} from 'lodash';
import {PaymentsStatuses} from '../PaymentsTable/Row';
import {countryCodes, getCountryName} from '../../../../utils/constants';
import {downloadCsv} from '../../../../api';
import {queryObjectToString} from '../../../../api/helper';
import {DownloadOutlined} from '@ant-design/icons';


const {Option} = Select;
const {RangePicker} = DatePicker;

type Props = {
  onChangeFilter: (field: keyof any, value: any) => void,
  filters: any,
}


const Filters: FC<Props> = ({onChangeFilter, filters}) => {
  const [downloadLoader, setDownloadLoader] = useState(false)
  const download = () => {
    setDownloadLoader(true)
    downloadCsv('transactions',queryObjectToString(filters)).finally(() => setDownloadLoader(false));
  }
  const debouncedSearch = useCallback(
    debounce((name,nextValue) => onChangeFilter(name,nextValue), 500),
    [],
  );

  const handleSearch = (event:any) => {
    event.persist();
    const { value: nextValue,name } = event.target;
    debouncedSearch(name,nextValue);
  };

  return <Space size={'small'} className={s.filters}>
    <Select
      allowClear={true}
      style={{width: '120px'}}
      placeholder="Status"
      onChange={(e: string) => onChangeFilter("status", e)}
      showSearch={true}
      defaultValue={filters.filter?.status}
      filterOption={(input, option: any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {Object.keys(PaymentsStatuses).map((item: any) => (
        <Option value={item} key={item}>
          {/*@ts-ignore*/}
          {PaymentsStatuses[item]}
        </Option>
      ))}
    </Select>
    <Select
      allowClear={true}
      style={{minWidth: 150}}
      placeholder="All country"
      onChange={(e: string) => onChangeFilter("customer.country", e)}
      showSearch={true}
      defaultValue={filters.filter['customer.country']}
      filterOption={(input, option: any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {Object.keys(countryCodes).map((country: string) => (
        <Option defaultValue={country} key={country}>
          {getCountryName(country)}
        </Option>
      ))}
    </Select>
    <RangePicker
      allowClear
      placeholder={['Reg from', 'To']}
      format={['YYYY-MM-DD', 'YYYY-MM-DD']}
      defaultValue={filters?.filter?.created_at?.from && filters?.filter?.created_at?.to ? [moment(filters[filters?.filter?.created_at?.from]), moment(filters?.filter?.created_at?.to)] : undefined}
      onChange={(e: any) => {
        if (e) {
          const [from, to] = e;
          onChangeFilter("created_at", {
            from: moment(from).format('YYYY-MM-DD 00:00:00'),
            to: moment(to).format('YYYY-MM-DD 23:59:59')
          })
        } else {
          onChangeFilter("created_at", undefined)
        }
      }
      }
    />
    <Button
      onClick={() => download()}
      icon={<DownloadOutlined/>}
      loading={downloadLoader}
    >
      CSV
    </Button>
  </Space>
}

export default Filters;
