import NotFound from '../pages/NotFound';
import DashboardPage from '../pages/Dashboard';
import UsersPage from '../pages/UsersPage';
import UserIdPage from '../pages/UserIdPage';
import PaymentsPage from '../pages/PaymentsPage';
import EsimsPage from '../pages/EsimsPage';
import DevicesPage from '../pages/DevicesPage';
import PackagesPage from '../pages/PackagesPage';
import PackageIdPage from '../pages/PackageIdPage';
import DeviceIdPage from '../pages/DeviceIdPage';
import EsimIdPage from '../pages/EsimIdPage';
import PaymentsIdPage from '../pages/PaymentsIdPage';

const routes = [
	{
		title: 'Dashboard',
		url: '/dashboard',
		path: '/dashboard',
		element: DashboardPage,
		isMenuItem: true,
	},
	{
		title: 'Users',
		url: '/users',
		path: '/users',
		element: UsersPage,
		isMenuItem: true,
	},

	{
		title: 'User page',
		path: '/users/:userId',
		element: UserIdPage,
		isMenuItem: false,
	},
	{
		title: 'Payments',
		url: '/payments',
		path: '/payments',
		element: PaymentsPage,
		isMenuItem: true,
	},
	{
		title: 'Payment page',
		path: '/payments/:paymentId',
		element: PaymentsIdPage,
		isMenuItem: false,
	},
	{
		title: 'Esims',
		path: '/esims',
		url: '/esims',
		element: EsimsPage,
		isMenuItem: true,
	},
	{
		title: 'Devices',
		path: '/devices',
		url: '/devices',
		element: DevicesPage,
		isMenuItem: false,
	},
	{
		title: 'Device page',
		path: '/devices/:deviceId',
		element: DeviceIdPage,
		isMenuItem: false,
	},
	{
		title: 'Package page',
		path: '/packages/:packageId',
		element: PackageIdPage,
		isMenuItem: false,
	},
	{
		title: 'Esim page',
		path: '/esims/:esimId',
		element: EsimIdPage,
		isMenuItem: false,
	},
	{
		title:'Settings',
		url: '/settings',
		path: '/settings',
		element: PackagesPage,
		isMenuItem: true,
		submenu: [
			{
				title: 'Packages',
				url: '/settings/packages',
				path: '/settings/packages',
				element: PackagesPage,
				isMenuItem: true,
			},
			{
				title: 'Phones',
				url: '/settings/phones',
				path: '/settings/phones',
				element: PackagesPage,
				isMenuItem: true,
			},
		]
	},
	{
		title: 'Phones',
		path: '/settings/phones',
		url: '/settings/phones',
		element: UsersPage,
		isMenuItem: false,
	},
	{
		path: '/*',
		element: NotFound,
		isMenuItem: false,
	},
];

export default routes;
