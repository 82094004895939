import React, {FC} from "react";
import moment from "moment";
import {Tags} from "@vas-common/ui-kit";
import s from './esim.module.scss'
import {Link} from 'react-router-dom';
import {TEsim} from '../../../../api/Types/esims';
import { getFullName } from "../../../../api/helper";

type Prop = {
  item: TEsim
  onClick?: () => void
}

export const EsimStateTag = ({state}: { state: string|undefined }) => <Tags
  className={`${s.state} ${s[`state-${state?.toLowerCase()}`]}`} values={[{
  name: state||''
}]}/>

const Row: FC<Prop> = ({item}) => {
  return (
    <tr>
      <td className={'disabled'}>
        <Link className={`no-wrap link`} to={`/esims/${item.id}`}>
          <span className={`material-icons-outlined ${s.icon}`}>
sim_card
</span>
          <strong>{item.iccid}</strong></Link></td>
      <td className={'disabled'}>{item.imsi}</td>
      <td><EsimStateTag state={item.state}/></td>
      <td>{item.status}</td>
      <td>
        {item.customer_id ? <Link className={'link no-wrap'} to={`/users/${item.customer_id}`}>
          <span className={`material-icons-outlined ${s.icon}`}>
person
</span>
            {getFullName(item?.customer?.last_name,item?.customer?.first_name)}</Link>
          :<span className="disabled">none</span>
        }
      </td>
      <td>
        {item?.assigned_at ? moment(item.assigned_at).format("DD MMM YYYY") : <span className="disabled">none</span>}
      </td>
      <td>
        {item.activated_at ? moment(item.activated_at).format("DD MMM YYYY") : <span className="disabled">Not used yet</span>}
      </td>
      <td>
        <span className="disabled">{item.created_at ? moment(item.created_at).format("DD MMM YYYY") : ''}</span>
      </td>
    </tr>
  )
}

export default Row;
