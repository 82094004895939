import React, {useEffect, useState} from 'react';
import {Pagination, PaginationProps, Spin} from 'antd';
import {AdminTable, Container} from "@vas-common/ui-kit";

import Page from '../../components/Page';
import {fetchPackages} from '../../api/packages';
import up from '../UsersPage/users.module.scss';
import PackagesTable from './Components/PackagesTable';
import {useNavigate, useSearchParams} from 'react-router-dom';
import qs from 'qs';
import {LIMIT} from '../../utils/constants';

const Index = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState<any>({
    sort: {
      created_at: 'desc'
    },
    limit: LIMIT,
    page: 1,
    ...Object.fromEntries(
      new URLSearchParams(searchParams)
    )
  });

  const [packages, setPackages] = useState<any>({
    data: {},
    meta: {}
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true)
    fetchPackages(`?${qs.stringify(filters)}`)
    .then(data => {
      setPackages(data);
    })
    .finally(() =>
      setLoading(false)
    )
  }, [filters])

  const toggleSort = (selectedColumn: string) => {
    const currentColumn = filters.sort ? Object.keys(filters.sort)[0] : undefined;
    const isCurrentColumn = selectedColumn === currentColumn
    const sort = {
      [selectedColumn]: isCurrentColumn
        ? filters.sort[selectedColumn] === 'asc' ? 'desc' : 'asc'
        : 'desc'
    }
    setFilters({
      ...filters, sort, page: 1
    })
  }

  const onChangePage: PaginationProps['onChange'] = (page, sizePage) => {
    setFilters({...filters, page: page, limit: sizePage})
  };

  useEffect(() => {
    navigate({
      search: `?${qs.stringify(filters)}`,
    });
  }, [filters])
  return (
    <Page title={'Packages'}>
      <Container>
        <Spin spinning={loading}>
          <AdminTable
            title={'Packages'}
            isPage={true}
            caption={loading ||
              packages.meta.total && `${packages.meta.total} found`}>
            <PackagesTable
              data={packages.data}
              orderColumn={Object.keys(filters.sort)[0]}
              isOrderColumn={(filters.sort[Object.keys(filters.sort)[0]] === 'desc')}
              onClick={toggleSort}
            />
            {packages?.data?.length != packages?.meta?.total ? <Pagination
              className={up.pagination}
              current={filters.page}
              onChange={onChangePage}
              showSizeChanger
              pageSize={filters.limit} total={packages?.meta?.total}/> : ''}
          </AdminTable>
        </Spin>
      </Container>
    </Page>
  )
}

export default Index;
