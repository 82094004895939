import React from 'react';
import moment from "moment";
import {Spin} from "antd";
import {AdminDetailsHead} from "@vas-common/ui-kit";
import {TDevice} from '../../../api/Types/devices';

const Details = ({item, loading}: { item: TDevice, loading: boolean }) => {
  return (
    <Spin spinning={loading}>
      <AdminDetailsHead title={item.id}>
        <table>
          <tbody>
          <tr>
            <td>Advertising id:</td>
            <td>{item.advertising_id ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Apns token:</td>
            <td>{item.apns_token ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>App device id</td>
            <td>{item.app_device_id ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>App instance id</td>
            <td>{item.app_instance_id ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>App version</td>
            <td>{item.app_version ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Build version</td>
            <td>{item.build_version ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Bundle id</td>
            <td>{item.bundle_id ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Created at:</td>
            <td>{item?.created_at ? moment(item?.created_at).format("DD MMM YYYY") :
              <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Customer id</td>
            <td>{item.customer_id ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Fcm token</td>
            <td>{item.fcm_token ?? <span className="disabled">none</span>}</td>
          </tr>

          <tr>
            <td>Last activity at:</td>
            <td>{item?.last_activity_at ? moment(item?.last_activity_at).format("DD MMM YYYY") :
              <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Last ip</td>
            <td>{item.last_ip ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Name</td>
            <td>{item.name ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>System name</td>
            <td>{item.system_name ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>System version</td>
            <td>{item.system_version ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Vendor id</td>
            <td>{item.vendor_id ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Updated at:</td>
            <td>{item?.updated_at ? moment(item?.updated_at).format("DD MMM YYYY") :
              <span className="disabled">none</span>}</td>
          </tr>
          </tbody>
        </table>
      </AdminDetailsHead>
    </Spin>
  )
}

export default Details;
