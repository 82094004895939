import React, {useCallback, useEffect, useState} from 'react';
import {Pagination, Spin} from 'antd';
import {AdminTable, Container, UIHelpers} from "@vas-common/ui-kit";
import type {PaginationProps} from 'antd';
import UsersTable from './Components/UsersTable/UsersTable';
import up from './users.module.scss';
import Page from '../../components/Page';
import Filters from './Components/Filters';
import {fetchUsers} from '../../api/customers';
import moment from 'moment';
import {useLocation, useNavigate} from 'react-router';
import {LIMIT} from '../../utils/constants';
import {queryObjectToString} from '../../api/helper';
import {useLocationHook2} from '../../utils/hooks';

const Index = () => {
  const location = useLocation();
  const initialQuery=UIHelpers.getQueryParams(location.search)

  const {setParams:changeParams,state} = useLocationHook2({initialState: initialQuery});
  const [filters, setFilters] = useState<any>({
    sort: {
      created_at: 'desc'
    },
    filter:{
      has_active_esim: 'true'
    },
    limit: LIMIT,
    page: 1,
    ...state
  });

  const [users, setUsers] = useState<any>({
    data: [],
    meta: {}
  });
  const [userLoading, setLoading] = useState<boolean>(false);

  const onChangeFilter = (field: keyof any, value: string | any) => {
    if (field === 'search')
      filters.search = value;
    else
      filters.filter[field] = value;
    setFilters({...filters,page: 1})
  }


  useEffect(
    ()=> {
        setLoading(true)
        fetchUsers(queryObjectToString(filters)).then(data => {
          setUsers(data);
          setLoading(false)
        }).catch(()=>{
          setLoading(false)
        });
      },[filters]
  )

  const toggleSort = (selectedColumn: string) => {
    const currentColumn = filters.sort ? Object.keys(filters.sort)[0] : undefined;
    const isCurrentColumn = selectedColumn === currentColumn
    const sort = {
      [selectedColumn]: isCurrentColumn
        ? filters.sort[selectedColumn] === 'asc' ? 'desc' : 'asc'
        : 'desc'
    }
    setFilters({
      ...filters, sort, page: 1
    })
  }

  const onChangePage: PaginationProps['onChange'] = (page, sizePage) => {
    setFilters({...filters, page: page, limit: sizePage})
  };

  useEffect(() => {
    changeParams(filters)
  }, [filters])

  return (
    <Page title={'Users'}>
      <Container>
        <Spin spinning={userLoading}>
          <AdminTable
            isPage={true}
            caption={userLoading ||
              users?.meta?.total && `${users?.meta?.total} found`}
            title={'Users'}
            buttons={false}>
            <Filters
              onChangeFilter={onChangeFilter}
              filters={filters}
            />
            <UsersTable
              data={users.data}
              orderColumn={Object.keys(filters.sort)[0]}
              isOrderColumn={(filters.sort[Object.keys(filters.sort)[0]] === 'desc')}
              onClick={toggleSort}
            />
            {users?.data?.length != users?.meta?.total ? <Pagination
              className={up.pagination}
              current={Number(filters.page)}
              onChange={onChangePage}
              showSizeChanger
              pageSize={filters.limit} total={users?.meta?.total}/> : ''}
          </AdminTable>
        </Spin>
      </Container>
    </Page>
  )
}

export default Index;
