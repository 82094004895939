import React from 'react';
import Row from './Row';

const Index = (props: {
  data: any[],
  refetch: () => void
}) => {
  let {data, refetch} = props;
  return (
    <table>
      <tbody>
      <tr>
        <th>Name</th>
        <th>Volume</th>
        <th>Remain</th>
        <th>
          Last Usage At
        </th>
        <th>Period</th>
        <th>Status</th>
        <th/>
      </tr>
      {data.length > 0 ? (
        data.map((item: any, idx: number) => <Row key={idx} item={item} refetch={()=>refetch()}/>)
      ) : (
        <tr>
          <th colSpan={9}>
            <div className={'empty'}>
              There are no records
            </div>
          </th>
        </tr>
      )}
      </tbody>
    </table>
  )
}

export default Index;
