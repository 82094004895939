import React from 'react';
import Row from './Row';
import {ThSorted} from '@vas-common/ui-kit';
import {TPayment} from '../../../../api/Types/payments';

const Index = (props: {
  data: TPayment[],
  isOrderColumn: boolean,
  orderColumn: string,
  onClick: (name: string) => any,
  sortEnable?: boolean
}) => {
  const {data, sortEnable} = props;
  const getSortedColumn = (key: string, text: string) =>
    sortEnable ? <ThSorted name={key} {...props}>
        {text}
      </ThSorted>
      : <th>{text}</th>

  return (
    <table>
      <tbody>
      <tr>
        <th></th>
        <th>Total</th>
        <th>Amount</th>
        <th>Vat</th>
        {getSortedColumn('refunded_at', 'Refunded At')}
        {getSortedColumn('created_at', 'Created At')}
        {getSortedColumn('paid_at', 'Paid At')}
        <th>Status</th>
      </tr>
      {data.length > 0 ? (
        data.map((item: any, idx: number) => <Row key={idx} item={item}/>)
      ) : (
        <tr>
          <th colSpan={10}>
            <div className={'empty'}>
              There are no records
            </div>
          </th>
        </tr>
      )}

      </tbody>
    </table>
  )
}

export default Index;
