import React from 'react';
import Row from './Row';
import {ThSorted} from '@vas-common/ui-kit';
import {TCustomer} from '../../../../api/Types/customers';

const UsersTable = (props: {
  data: TCustomer[],
  isOrderColumn: boolean,
  orderColumn: string,
  onClick: (name: string) => any,
}) => {
  const {data} = props;
  return (
    <table>
      <tbody>
      <tr>
        <th>Id</th>
        <th>Name</th>
        <th>Email</th>
        <th>Sign in With</th>
        <th>Country</th>
        <th>eSim</th>
        <th>Package</th>
        <ThSorted name={'last_active_at'} {...props}>
          Last Active at
        </ThSorted>
        <ThSorted name={'created_at'} {...props}>
          Registered At
        </ThSorted>
      </tr>
      {data.length > 0 ? (
        data.map((item: any, idx: number) => <Row key={idx} item={item}/>)
      ) : (
        <tr>
          <th colSpan={9}>
            <div className={'empty'}>
              There are no records
            </div>
          </th>
        </tr>
      )}

      </tbody>
    </table>
  )
}

export default UsersTable;
