import React from 'react';
import {useParams} from 'react-router-dom';
import Details from './components/Details';
import Page from '../../components/Page';
import useRequest from '../../components/Hooks/useRequest';
import {fetchDeviceById} from '../../api/other';

const Index = () => {
  const {deviceId} = useParams();
  const {data: device, loading} = useRequest(fetchDeviceById, {id: deviceId});
  return (
    <Page title={``}>
      <div style={{maxWidth: '900px', margin: '0 auto'}}>
        <Details item={device} loading={loading}/>
      </div>
    </Page>
  )
}

export default Index;
