import {useCallback, useState} from 'react';
import {useNavigate} from 'react-router';
import qs from 'qs';

export const useLocationHook2 = ({initialState, deps = []}: { initialState: any, deps?: any[] }) => {
  const [state, setState] = useState<any>(initialState);
  const navigate = useNavigate();

  const setParams = useCallback((objParams: any) => {
    setState(objParams)
    navigate(`?${qs.stringify({...objParams},{ arrayFormat: 'comma' })}`)
  }, deps)

  const getNavigate = useCallback((navigate: any) => {
    return navigate(`?${qs.stringify({...state})}`);
  }, [state])

  return {setParams, getNavigate, state}
}