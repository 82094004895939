import React, {FC} from "react";
import moment from "moment";
import {Tags} from "@vas-common/ui-kit";
import s from './payments.module.scss'
import {Link} from 'react-router-dom';
import {TPayment} from '../../../../api/Types/payments';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import e from '../../../EsimsPage/Components/EsimsTable/esim.module.scss';
import {getFullName} from '../../../../api/helper';
import exp from 'constants';
import { fixedNumber } from "../../../../utils";

type Prop = {
  item: TPayment,
  onClick?: () => void
  clients?: any
}
export const Tag = ({status}: { status: string }) => <Tags className={`${s.status} ${s[`status-${status}`]}`} values={[{
  name: status
}]}/>

export const PaymentsStatuses = {
  0: "Pending",
  2: "Authorized",
  3: "Refunded",
  4: "Canceled",
  5: "Declined"
}

export const getPaymentStatus = (status: number) => {
  switch (status) {
    case 0:
      return <Tag status={'pending'}/>
    case 2:
      return <Tag status={'authorized'}/>
    case 3:
      return <Tag status={'refunded'}/>
    case 4:
      return <Tag status={'canceled'}/>
    case 5:
      return <Tag status={'declined'}/>
    default:
      return <Tag status={status.toString()}/>
  }
}

const Row: FC<Prop> = ({item}) => {
  return (
    <tr>
      <td className={'no-wrap'}><Link className={'link'}
                                      to={`/payments/${item.id}`}><strong>{`${item.pay_amount}`} {item.currency}</strong></Link>
      </td>
      <td className={s.product}>{item?.product_names || <span className={'disabled'}>none</span>}</td>
      <td>{item?.customer?.country ? `${getUnicodeFlagIcon(item?.customer?.country)} ${item?.customer?.country}` :
        <span className="disabled">none</span>}</td>
      <td className={s.user}>{item?.customer
        ?
        <Link className={'link'} to={`/users/${item.customer_id}`}>
          <span className={`material-icons-outlined ${e.icon}`}>
person
</span>{getFullName(item?.customer?.last_name, item?.customer?.first_name)}</Link>
        : <span className={'disabled'}>none</span>}</td>
      <td className={s.width50}><span
        className={Number(item.pay_amount) <= 0 ? 'disabled' : ''}>{fixedNumber(item.pay_amount)}</span></td>
      <td className={s.number}><span
        className={Number(item.pay_total) <= 0 ? 'disabled' : ''}>{fixedNumber(item.pay_total)}</span>
      </td>
      <td className={s.number}><span className={Number(item.pay_vat) <= 0 ? 'disabled' : ''}>{item.pay_vat}</span></td>
      <td className={s.number}><span
        className={Number(item.refund_amount) <= 0 ? 'disabled' : ''}>{item.refund_amount}</span></td>
      <td className={s.thDate}>
        {item.refunded_at ? moment(item.refunded_at).format("DD MMM YYYY") : <span className={'disabled'}>none</span>}
      </td>
      <td className={s.thDate}>
        {item.paid_at ? <span className={s.green}>{moment(item.paid_at).format("DD MMM YYYY")}</span> :
          <span className={'disabled'}>none</span>}
      </td>
      <td>{getPaymentStatus(item.status)}</td>
      <td className={s.thDate}>
        {item.created_at ? moment(item.created_at).format("DD MMM YYYY") : <span className={'disabled'}>none</span>}
      </td>
    </tr>
  )
}

export default Row;
