import {useState, useEffect} from 'react'

const useRequest = (
  request: any,
  params?: any,
  dataFormatted?: (data: any) => any,
  deps: any = [],
  defaultData?: any) => {

  const [state, setState] = useState<{
    loading: boolean,
    data: [] | any,
    error: any
  }>({
    loading: false,
    data: defaultData !== undefined ? defaultData : [],
    error: null
  })

  async function fetch(args?: any) {
    setState({...state, loading: true})
    return request(args)
    .then((data: any) => {
      const calcData = dataFormatted ? dataFormatted(data) : data;
      setState({...state, loading: false, data: calcData})
    })
    .catch((error: any) => {
      setState({...state, loading: false, error})
    })
  }

  useEffect(() => {
      fetch(params)
  }, deps ? deps : [])

  return {...state, fetch, setState}
}

export default useRequest