import React, {FC} from "react";
import moment from "moment";
import {Link} from 'react-router-dom';
import {TDevice} from '../../../../api/Types/devices';
type Prop = {
  item: TDevice,
  onClick?: () => void
  clients?: any
}

const Row: FC<Prop> = ({item}) => {
  return (
    <tr>
      <td><Link className={'link'} to={`/devices/${item.id}`}><strong>{item.system_name||"Web"}</strong></Link></td>
      <td>{item.app_version}</td>
      <td>{item.system_version}</td>
      <td>
        {item.created_at ? moment(item.created_at).format("DD MMM YYYY") : ''}
      </td>
      <td>
        {item.last_activity_at ? moment(item.last_activity_at).fromNow() : <span className="disabled">none</span>}
      </td>
    </tr>
  )
}

export default Row;
