import React from 'react';
import {Routes, Route, BrowserRouter, Navigate} from "react-router-dom";
import AppRoute from './components/AppRoute';
import routes from './utils/routes';
import "./styles/index.scss";
import {UserProvider} from './components/Hooks/UserProvider';

const App = () => {
  return (
    <UserProvider>
        <BrowserRouter>
          <Routes>
            {routes.map((route, i) =>
              route.submenu?.length
                ? route.submenu.map((subroute, subIndex) =>
                  <Route
                    key={subIndex}
                    path={subroute.path}
                    element={
                      <AppRoute>
                        <subroute.element/>
                      </AppRoute>
                    }
                  />)
                : <Route
                  key={i}
                  path={route.path}
                  element={
                    <AppRoute>
                      <route.element/>
                    </AppRoute>
                  }
                />
            )}
            <Route
              key={'/'}
              path={'/'}
              element={
                <Navigate to={'/dashboard'}/>
              }
            />
          </Routes>
        </BrowserRouter>
    </UserProvider>
  )
}

export default App;
