export const ssoAuthMethod:{
  [key: string]: string;
}= {
  facebook: "Facebook",
  google: "Google",
  apple: "Apple",
  email: "Email",
};
export const getAuthWithName = (key:string) => {
  if (ssoAuthMethod.hasOwnProperty(key)) {
    return ssoAuthMethod[key];
  } else {
    return key;
  }
};

const comparator = (column: string): any => {
  switch (column) {
    case "id":
      return (p1: any, p2: any) => p1.id - p2.id;
    case "createdAt":
      return (p1: any, p2: any) => (p1.createdAt || 0) - (p2.createdAt || 0)
    case "activeAt":
      return (p1: any, p2: any) => (p1.activeAt || 0) - (p2.activeAt || 0)

    default:
      return null;
  }
}
export const sort = (items: any[], column: string, desc: boolean): any[] => {
  items = items.sort(comparator(column));
  if (desc) items = items.reverse();
  return items;
};