import {Form, Input, Modal, notification, Spin} from 'antd';
import React, {useState} from 'react';
import {createPlan} from '../../../api/packages';
import {TPlan} from '../../../api/Types/packages';

const CreatePackagePlanForm = (props: { isVisible: boolean, handleClose: () => void, fetch: () => void, package_id: TPlan['package_id'] }): JSX.Element => {
  const {handleClose, isVisible, fetch, package_id} = props;
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async () => {
    const values = await form.validateFields();
    setLoading(true);
    createPlan({package_id,currency:'EUR',...values}).then(() => {
      notification.success({message: `User has been update`});
      fetch();
      handleClose()
    }).finally(() => setLoading(false))
  };
  return <Modal
    width={400}
    title={'Create plan'}
    visible={isVisible}
    onOk={() => form.submit()}
    okText={'Create'}
    okButtonProps={{disabled: isLoading}}
    onCancel={handleClose}
    destroyOnClose={true}
  >
    <Spin spinning={isLoading}>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name={'volume'}
          required
          label="Volume"
          rules={[
            {
              required: true,
              message: 'Please input volume',
            }
          ]}
        >
          <Input
            type={'number'}
            placeholder={`Volume`}
          />
        </Form.Item>
        <Form.Item
          name={'days'}
          required
          label="Period"
          rules={[
            {
              required: true,
              message: 'Please input period',
            },
          ]}
        >
          <Input
            type={'number'}
            placeholder={`Period`}
          />
        </Form.Item>
        <Form.Item
          name={'price'}
          required
          label="Price"
          rules={[
            {
              required: true,
              message: 'Please input price',
            },
          ]}
        >
          <Input
            type={'number'}
            placeholder={`Price`}
          />
        </Form.Item>
      </Form>
    </Spin>
  </Modal>
}

export default CreatePackagePlanForm;