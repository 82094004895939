import React from 'react';
import Row from './Row';
import {TEsim} from '../../../../api/Types/esims';

import e from './esim.module.scss';

const Index = (props: {
  data: TEsim[],
  isOrderColumn: boolean,
  orderColumn: string,
  onClick: (name: string) => any,
}) => {
  const {data} = props;

  return (
    <table>
      <tbody>
      <tr>
        <th>ICCID</th>
        <th className={e.status}>State</th>
        <th className={e.techStatus}>Tech Status</th>
        <th>
          Paid at
        </th>
        <th>
          Installed At
        </th>
      </tr>
      {data.length > 0 ? (
        data.map((item: any, idx: number) => <Row key={idx} item={item}/>)
      ) : (
        <tr>
          <th colSpan={5}>
            <div className={'empty'}>
              There are no records
            </div>
          </th>
        </tr>
      )}

      </tbody>
    </table>
  )
}

export default Index;
