import React from 'react';
import Page from "../../components/Page";

function NotFound() {
    return (<Page title={'Page not found'}>
            <h1>Page not found</h1>
        </Page>
    );
}

export default NotFound;
