import React from 'react';
import s from './page.module.scss'
import {useTitle} from 'react-use';

const Page = ({children, className, title}: { children: any, className?: string, title?: string }) => {
  useTitle(`ESIM ADMIN ${title ? `| ${title}` : ''}`)
  return <div className={`${s.page} ${className}`}>
    <main className={s.content}>
      {children}
    </main>
  </div>
}

export default Page;