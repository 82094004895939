import React, {useCallback, useEffect, useState} from 'react';
import {Pagination, PaginationProps, Spin} from 'antd';
import {
  AdminTable, Container, UIHelpers,
  // useLocationHook
} from "@vas-common/ui-kit";

import Page from '../../components/Page';
import ESimsTable from './Components/EsimsTable';
import {fetchEsims} from '../../api/other';
import up from '../UsersPage/users.module.scss';
import qs from 'qs';
import Filters from './Components/Filters';
import {useLocation, useNavigate} from 'react-router';
import {LIMIT} from '../../utils/constants';
import {useLocationHook2} from '../../utils/hooks';

const Index = () => {
  const location = useLocation();
  const initialQuery = UIHelpers.getQueryParams(location.search)

  const {setParams: changeParams, state} = useLocationHook2({initialState: initialQuery});
  const [filters, setFilters] = useState<any>({
    sort: {
      created_at: 'desc'
    },
    filter: {},
    limit: LIMIT,
    page: 1,
    ...state
  });

  const [esims, setEsims] = useState<any>({
    data: {},
    meta: {}
  });
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeFilter = (field: keyof any, value: string | any) => {
    if (field === 'search')
      filters.search = value;
    else
      filters.filter[field] = value;
    setFilters({...filters, page: 1})
  }

  useEffect(() => {
      setLoading(true)
      fetchEsims(`?${qs.stringify(filters)}`)
      .then(data => {
        setEsims(data);
      }).finally(() => setLoading(false));
    },
    [filters]
  )

  const toggleSort = (selectedColumn: string) => {
    const currentColumn = filters.sort ? Object.keys(filters.sort)[0] : undefined;
    const isCurrentColumn = selectedColumn === currentColumn
    const sort = {
      [selectedColumn]: isCurrentColumn
        ? filters.sort[selectedColumn] === 'asc' ? 'desc' : 'asc'
        : 'desc'
    }
    setFilters({
      ...filters, sort, page: 1
    })
  }

  const onChangePage: PaginationProps['onChange'] = (page, sizePage) => {
    setFilters({...filters, page: page, limit: sizePage})
  };

  useEffect(() => {
    changeParams(filters)
  }, [filters])

  return (
    <Page title={'eSIMs'}>
      <Container>
        <Spin spinning={loading}>
          <AdminTable
            title={'Esims'}
            isPage={true}
            caption={loading ||
              esims.meta.total && `${esims.meta.total} found`}>
            <Filters
              onChangeFilter={onChangeFilter}
              filters={filters}
            />
            <ESimsTable
              data={esims.data}
              orderColumn={Object.keys(filters?.sort)[0]}
              isOrderColumn={(filters?.sort[Object.keys(filters?.sort)[0]] === 'desc')}
              onClick={toggleSort}
            />
            {esims?.data?.length != esims?.meta?.total ? <Pagination
              className={up.pagination}
              current={filters.page}
              onChange={onChangePage}
              showSizeChanger
              pageSize={filters.limit} total={esims?.meta?.total}/> : ''}
          </AdminTable>
        </Spin>
      </Container>
    </Page>
  )
}

export default Index;
