import React, {FC} from "react";
import moment from "moment";
import s from './esim.module.scss'
import {Link} from 'react-router-dom';
import {TEsim} from '../../../../api/Types/esims';
import {EsimStateTag} from '../../../EsimsPage/Components/EsimsTable/Row';

type Prop = {
  item: TEsim
  onClick?: () => void
  clients?: any
}

const Row: FC<Prop> = ({item}) => {
  return (
    <tr>
      <td className={'disabled'}>
        <Link className={'link'} to={`/esims/${item.id}`}>
          <span className={`material-icons-outlined ${s.icon}`}>
sim_card
</span>
          <strong>{item.iccid}</strong></Link></td>
      <td><EsimStateTag state={item.state}/></td>
      <td>{item.status}</td>
      <td>
        {item.assigned_at ? moment(item.assigned_at).format("DD MMM YYYY") : <span className="disabled">none</span>}
      </td>
      <td>
        {item.activated_at ? moment(item.activated_at).format("DD MMM YYYY") : <span className="disabled">Not used yet</span>}
      </td>
    </tr>
  )
}

export default Row;
