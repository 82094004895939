import axios, {AxiosResponse} from "axios";
import {notification} from "antd";
import {blob} from 'stream/consumers';

export const httpClient = axios.create({
  withCredentials: true,
  headers: {
    'Cache-Control': 'no-cache',
  },
});
httpClient.interceptors
.response.use(
  response => resolveRequest(response),
  error => rejectRequest(error),
)

export const resolveRequest = async (response: AxiosResponse) => response.data;
export const rejectRequest = async (reject: any) => {
  // this case if response returned specific java message
  const isBadResponseForUser = reject?.response?.data.errorCode === 500;

  const isBlobResponse = reject?.response?.request?.responseType === 'blob'
  const blobMessage = isBlobResponse ? JSON.parse(await reject?.response.data.text()).message : false

  const description = isBadResponseForUser
    ? 'Bad request, please try again later'
    : reject?.response?.data?.errorReason || reject?.response?.data.message || blobMessage || 'Bad request, please try again later'
  notification.error({
    message: `Error (${reject?.response?.status || reject?.response?.data.errorCode || reject?.response?.data.statusCode || 'unknown'})`,
    description
  })
  throw reject
};

type TAuthMe = {
  currentProject?: { id: number, name: string, historyUrl: string }
  email: string
  id: number
  isDeleted: boolean
  name?: string
  phoneNumber?: string | number
  photoUrl: string | null
  role: { id: number, name: string, type: string }
  teamId: null | number
}

export async function me(): Promise<TAuthMe | any> {
  return httpClient.get(`/aac/auth/me`)
  .catch((reject) => {
    if (reject?.response?.status == 401) {
      window.location.pathname = '/login';
    }
    rejectRequest(reject)
  })
}

export const downloadCsv = async (entity: string, params: any) => {
  return httpClient.get(`/admin/v1/${entity}/export${params}`, {
    responseType: 'blob'
  }).then((data: any) => {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${entity}.csv`);
    document.body.appendChild(link);
    link.click();
    return 'complete'
  })
}