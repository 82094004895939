import React from 'react';
import moment from "moment";
import {Spin} from "antd";
import {AdminDetailsHead} from "@vas-common/ui-kit";
import s from '../../PackageIdPage/components/details.module.scss'
import e from '../../EsimsPage/Components/EsimsTable/esim.module.scss'

import {EsimStateTag} from '../../EsimsPage/Components/EsimsTable/Row';
import {TEsim} from '../../../api/Types/esims';
import {getFullName} from '../../../api/helper';
import {Link} from 'react-router-dom';

const Details = ({item, loading}: { item: TEsim, loading: boolean }) => {
  return (
    <Spin spinning={loading}>
      <AdminDetailsHead className={s.detailTitle} title={`eSim ${item?.iccid??''}`}>

        <table className={s.table}>
          <tbody>
          <tr>
            <td>Iccid:</td>
            <td>{item.iccid}</td>
          </tr>
          <tr>
            <td>Imsi:</td>
            <td>{item.imsi ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Lpa:</td>
            <td>{item.lpa ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Eid:</td>
            <td>{item.eid ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>State</td>
            <td><EsimStateTag state={item.state}/></td>
          </tr>
          <tr>
            <td>Tech Status</td>
            <td>{item.status}</td>
          </tr>
          <tr>
            <td>Account_id:</td>
            <td>{item.account_id ?? <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Customer:</td>
            <td>{item.customer_id
              ? <Link className={'link no-wrap'} to={`/users/${item.customer_id}`}>
          <span className={`material-icons-outlined ${e.icon}`}>
person
</span>
                <strong>{getFullName(item?.customer?.last_name, item?.customer?.first_name)}</strong></Link>
              : <span className="disabled">none</span>
            }</td>
          </tr>
          <tr>
            <td>Activated at:</td>
            <td>{item?.created_at ? moment(item?.activated_at).format("DD MMM YYYY") :
              <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Created at:</td>
            <td>{item?.created_at ? moment(item?.created_at).format("DD MMM YYYY") :
              <span className="disabled">none</span>}</td>
          </tr>
          <tr>
            <td>Updated at:</td>
            <td>{item?.updated_at ? moment(item?.updated_at).format("DD MMM YYYY") :
              <span className="disabled">none</span>}</td>
          </tr>
          </tbody>
        </table>
      </AdminDetailsHead>
    </Spin>
  )
}

export default Details;
