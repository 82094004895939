import React from 'react';
import {useParams} from 'react-router-dom';
import Details from './components/Details';
import Page from '../../components/Page';
import useRequest from '../../components/Hooks/useRequest';
import {fetchPackageById} from '../../api/packages';
import PlansBlock from './components/PlansBlock';

const Index = () => {
  const {packageId} = useParams();
  const {data: pack, loading, fetch:fetchPackage} = useRequest(fetchPackageById, {id: packageId});
  return (
    <Page title={pack?.name}>
      <div style={{maxWidth: '900px', margin: '0 auto'}}>
        <Details item={pack} loading={loading} fetch={()=>fetchPackage({id: packageId})}/>
        <PlansBlock package_id={packageId}/>
      </div>
    </Page>
  )
}

export default Index;
