import React from 'react';
import {Container} from "@vas-common/ui-kit";
import './Dashboard.scss';
import Page from '../../components/Page';
import Cards from './Components/Cards';
import {Col, Row, Spin} from 'antd';
import useRequest from '../../components/Hooks/useRequest';
import {
  fetchCustomersActivity,
  fetchCustomersRegistrations,
} from '../../api/customers';
import qs from 'qs';
import moment from 'moment';
import {TPaymentsChart, TregistrationsChart} from '../../api/Types/common';
import {fetchSimCardsStates, fetchTransactionsPaidDay, fetchTransactionsPaidSum} from '../../api/other';
import RegistarionChart from './Components/Chart/RegistarionChart';
import PaymentsChart from './Components/Chart/PaymentsChart';

const Index = () => {
  //cards
  const {
    data: activitiesMonth,
    loading: activitiesMonthLoading,
  } = useRequest(fetchCustomersActivity, `?${qs.stringify({from: moment().subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss')})}`, undefined, [], {count: 0});
  const {
    data: activitiesDay,
    loading: activitiesDayLoading,
  } = useRequest(fetchCustomersActivity, `?${qs.stringify({from: moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm:ss')})}`, undefined, [], {count: 0});

  const {
    data: simCardsStates,
    loading: simLoading,
  } = useRequest(fetchSimCardsStates, {}, ((states: { state: string, count: number }[]) => {
    const obj: any = {};
    states.forEach(item => obj[item.state] = item.count)
    return obj;
  }));

  const {
    data: transactionsPaidSumDay,
    loading: paidSumDayLoading,
  } = useRequest(fetchTransactionsPaidSum, `?${qs.stringify({from: moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm:ss')})}`, undefined, [],{amount: 0});
  const {
    data: transactionsPaidSumMonth,
    loading: paidSumMonthLoading,
  } = useRequest(fetchTransactionsPaidSum, `?${qs.stringify({from: moment().subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss')})}`, undefined, [], {amount: 0});

  // charts
  const calcChartData = (data: TregistrationsChart | TPaymentsChart | any) => {
    return data?.items.map((el: any) => {
        return {
          date: moment(el.date).format('MMM DD'),
          'Total': el.total,
          'With sim': el.with_sim,
          'Count': el.count,
          'Amount': el.amount
        };
      }
    )
  }
  const {
    data: registrations,
    loading: registrationsLoading,
  } = useRequest(fetchCustomersRegistrations, '', calcChartData);
  const {
    data: payments,
    loading: paymentsLoading,
  } = useRequest(fetchTransactionsPaidDay, '', calcChartData);

  return (
    <Page title={'Dashboard'}>
      <div className={'wrapper-dashboard-page'}>
        <Container className={'dashboard-header'}>
          <h1>
            Yolla eSIM Admin Dashboard
          </h1>
          <p className="desc">
            Internal admin panel to manage users, plans, payments, and settings of product <a className={'link desc'}
                                                                                              href="https://yollaesim.com">https://yollaesim.com</a>.
          </p>
        </Container>

        <Container className={'cards-wrapper'}>
          <Cards
            activitiesMonth={activitiesMonth}
            activitiesMonthLoading={activitiesMonthLoading}
            activitiesDay={activitiesDay}
            activitiesDayLoading={activitiesDayLoading}
            simCardsStates={simCardsStates}
            simLoading={simLoading}
            transactionsPaidSumDay={transactionsPaidSumDay}
            paidSumDayLoading={paidSumDayLoading}
            transactionsPaidSumMonth={transactionsPaidSumMonth}
            paidSumMonthLoading={paidSumMonthLoading}
          />
        </Container>

        <Container className={'charts'}>
          <Row justify={'space-between'}>
            <Col span={12}>
              <Spin spinning={registrationsLoading}>
                <div className={'wrapper-chart first'}>
                  <RegistarionChart
                    data={registrations} height={188}
                  />
                </div>
              </Spin>
            </Col>

            <Col span={12}>
              <Spin spinning={paymentsLoading}>
                <div className={'wrapper-chart second'}>
                  <PaymentsChart
                    data={payments} height={188}
                  />
                </div>
              </Spin>
            </Col>
          </Row>
        </Container>
      </div>
    </Page>
  )
}

export default Index;
