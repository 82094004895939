import {TwitterOutlined,GoogleOutlined,MailOutlined,FacebookOutlined,AppleOutlined} from '@ant-design/icons';
import React from 'react';
import s from './icons.module.scss';

export const getSignIcon=(key:string)=> {
  switch (key) {
    case 'twitter':
      return <TwitterOutlined/>
    case 'google':
      return <GoogleOutlined />
    case 'facebook':
      return <FacebookOutlined />
    case 'apple':
      return <AppleOutlined />
    case 'mail':
    default:
      return <MailOutlined />
  }
}

export const CheckIcon=({enable}:{enable:boolean})=>enable
  ?<span className={`${s.icon} material-icons ${s.green}`}>check_circle</span>
  :<span className={`${s.icon} material-symbols-outlined ${s.gray}`}>check_circle</span>