import React, {FC} from "react";
import moment from "moment";
import {Link} from 'react-router-dom';
import {TPayment} from '../../../../api/Types/payments';
import {getPaymentStatus} from "../../../PaymentsPage/Components/PaymentsTable/Row";
import {fixedNumber} from '../../../../utils';

type Prop = {
  item: TPayment,
  onClick?: () => void
  clients?: any
}

const Row: FC<Prop> = ({item}) => {
  return (
    <tr>
      <td><Link className={'link'}
                to={`/payments/${item.id}`}><strong>{`${item.pay_amount}`} {item.currency}</strong></Link></td>
      <td>{fixedNumber(item.pay_total)}</td>
      <td>{fixedNumber(item.pay_amount)}</td>
      <td><span className={Number(item.pay_vat) <= 0 ? 'disabled' : ''}>{item.pay_vat}</span></td>
      <td>
        {item.refunded_at ? moment(item.refunded_at).format("DD MMM YYYY") : <span className={'disabled'}>none</span>}
      </td>
      <td>
        {item.created_at ? moment(item.created_at).format("DD MMM YYYY") : <span className={'disabled'}>none</span>}
      </td>
      <td>
        {item.paid_at ? moment(item.paid_at).format("DD MMM YYYY") : <span className={'disabled'}>none</span>}
      </td>

      <td>{getPaymentStatus(item.status)}</td>
    </tr>
  )
}

export default Row;
