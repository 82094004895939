import React from 'react';
import {useParams} from 'react-router-dom';
import Details from './components/Details';
import Page from '../../components/Page';
import useRequest from '../../components/Hooks/useRequest';
import { fetchPaymentById} from '../../api/other';

const Index = () => {
  const {paymentId} = useParams();
  const {data: item, loading} = useRequest(fetchPaymentById, {id: paymentId});
  return (
    <Page title={`Payment ${paymentId}`}>
      <div style={{maxWidth: '900px', margin: '0 auto'}}>
        <Details item={item} loading={loading}/>
      </div>
    </Page>
  )
}

export default Index;
