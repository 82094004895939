import React from 'react';
import Row from './Row';
import { TPlan} from '../../../../api/Types/packages';

const Index = (props: {
  data: TPlan[],
  fetch:()=>void
}) => {
  const {data,fetch} = props;

  return (
    <table>
      <tbody>
      <tr>
        <th>Enabled</th>
        <th>Volume</th>
        <th>Period</th>
        <th>Price</th>
        <th>Paid</th>
        <th>Active</th>
        <th/>
      </tr>
      {data.length > 0 ? (
        data.map((item: any, idx: number) => <Row key={idx} item={item} fetch={fetch}/>)
      ) : (
        <tr>
          <th colSpan={7}>
            <div className={'empty'}>
              There are no records
            </div>
          </th>
        </tr>
      )}
      </tbody>
    </table>
  )
}

export default Index;
